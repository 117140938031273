import React, { Fragment, useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { USER_TYPES } from '../utils/constants';

// context
import { useHomeBuyerAuth } from '../context/HomebuyerContext';

// components
import CoBuyerDocsCard from '../components/AdditionalDocuments/CoBuyerDocsCard';
import HomebuyerDocsCard from '../components/AdditionalDocuments/HomebuyerDocsCard';
import SidebarWithHeader from '../components/SidebarWithHeader';

// chakra
import { Container, Stack } from '@chakra-ui/react';

const HomebuyerDocuments = () => {
  const { authUser, handleNavigation } = useAuth();
  const { applicationData } = useHomeBuyerAuth();

  const isEmailVerified = authUser.emailVerified;

  const isAgent = authUser?.data?.type == USER_TYPES.AGENT;

  const [isLargeContainer, setIsLargeContainer] = useState(false);

  const [fileSelected, setFileSelected] = useState('file1');
  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    // if (authUser) {
    //   handleNavigation(authUser?.data);
    // }
  }, []);

  return (
    <Fragment>
      <SidebarWithHeader userMode="onboarding">
        <Container
          maxW={isLargeContainer == true ? 'container.md' : 'container.sm'}
        >
          <Stack spacing="10">
            <HomebuyerDocsCard />

            {applicationData?.stepFive[0].answer === 'yes' && (
              <CoBuyerDocsCard />
            )}
          </Stack>
        </Container>
      </SidebarWithHeader>
    </Fragment>
  );
};

export default HomebuyerDocuments;
