import React, { Fragment, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Link,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { Field, Formik } from 'formik';
import { useAuth } from '../context/AuthContext';
import { sendResetEmailSchema } from '../validations/SendResetEmailSchema';

import { Spinner } from '@chakra-ui/react';

const ResetPassword = () => {
  const [isDesktopScreen, setIsDesktopScreen] = useState(false);
  const [sectionLoading, setSectionLoading] = useState(false);
  const [sectionStep, setSectionStep] = useState(0);

  const toast = useToast();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({ minWidth: 992 });

  const {
    handleSendPasswordResetEmail,
    authLoading,
    authUser,
    setAuthLoading,
  } = useAuth();

  const handleSubmit = async values => {
    setSectionLoading(true);
    const { error, errorMessage } = await handleSendPasswordResetEmail(
      values.email
    );
    if (error) {
      if (errorMessage.message.toString().includes('auth/user-not-found')) {
        toast({
          title: 'Email Not Found',
          description:
            'The email you provided is not registered with Utopia Homes',
          status: 'error',
          duration: 4000,
          isClosable: true,
          position: 'top-right',
        });
      } else {
        toast({
          title: 'Error on Forget Password',
          description: error,
          status: 'error',
          duration: 4000,
          isClosable: true,
          position: 'top-right',
        });
      }
    } else {
      setSectionStep(1);
      toast({
        title: 'Reset email sent',
        description: 'Follow email instructions to reset email',
        status: 'success',
        duration: 4000,
        isClosable: true,
        position: 'top-right',
      });
      navigate('/email_sent');
    }
    setSectionLoading(false);
  };

  useEffect(() => {
    setIsDesktopScreen(isDesktop);
  }, [isDesktop]);

  useEffect(() => {
    const fetchData = async () => {
      setAuthLoading(true);
      if (authUser) {
        navigate('/');
      }
      setAuthLoading(false);
    };

    fetchData().catch(console.error);
  }, []);

  const SubmitSection = (
    <Fragment>
      <Box>
        <Heading mb="2">Forgot you Password?</Heading>{' '}
        <Text fontSize="md" as="b" color="gray.600">
          Provide your account email{' '}
        </Text>{' '}
      </Box>
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={sendResetEmailSchema}
        onSubmit={async values => {
          handleSubmit(values);
        }}
      >
        {formik => (
          <form
            onSubmit={e => {
              e.preventDefault();
              formik.handleSubmit(e);
            }}
          >
            <Box>
              <Stack spacing="7">
                <FormControl>
                  <FormLabel color="gray">Email</FormLabel>

                  <Field
                    as={Input}
                    id="email"
                    name="email"
                    type="email"
                    disabled={authLoading}
                  />
                </FormControl>
                <Button size="lg" type="submit">
                  Sent Reset Email
                </Button>
              </Stack>
              <Flex align="center" justify="center" mt="3">
                <Text fontSize="md" as="b" mr="3">
                  Know your password?
                </Text>
                <Link
                  fontSize="md"
                  as="b"
                  color="blue.500"
                  onClick={() => {
                    navigate('/login');
                  }}
                >
                  Log In
                </Link>
              </Flex>
              {/* </Stack> */}
            </Box>
          </form>
        )}
      </Formik>
    </Fragment>
  );

  const ConfirmationSection = (
    <Fragment>
      <Stack align="center" textAlign="center" spacing="5">
        <Image
          boxSize="100px"
          objectFit="cover"
          src="https://i.imgur.com/0BNTHtH.png"
          alt="Dan Abramov"
        />
        <Heading fontSize={'2xl'} mb="6">
          Recovery Email Sent
        </Heading>
        <Text fontSize={'lg'}>
          We sent a password reset link to your email if there was a Utopia
          account associated with it. Please allow up to 5 minutes for the email
          to arrive.
        </Text>
        <Text fontSize={'lg'}>
          If you are unable to access your account, please contact
          support@utopia.cx
        </Text>
      </Stack>
    </Fragment>
  );

  const LoadingSection = (
    <Fragment>
      <Flex direction="column" align="center" justify="center" minH="100vh">
        <Stack spacing="2">
          <Box>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Box>
        </Stack>
      </Flex>
    </Fragment>
  );

  const renderSwitch = index => {
    switch (index) {
      case 0:
        return SubmitSection;
      case 1:
        return ConfirmationSection;
      default:
        return SubmitSection;
    }
  };

  return (
    <Fragment>
      {sectionLoading ? (
        <LoadingSection />
      ) : (
        <Stack minH={'100vh'} direction={{ base: 'column', md: 'row' }}>
          <Flex p={8} flex={1} align={'center'} justify={'center'}>
            <Stack spacing={4} w={'full'} maxW={'md'}>
              {renderSwitch(sectionStep)}
            </Stack>
          </Flex>
          <Flex flex={1}>
            <Image
              onClick={() => {
                window.location.assign('https://www.utopiahomes.us/');
              }}
              style={{ cursor: 'pointer' }}
              alt={'Login Image'}
              objectFit={'cover'}
              src={'https://i.imgur.com/JkNbeV1.jpg'}
            />
          </Flex>
        </Stack>
      )}
    </Fragment>
  );

  // return (
  //   <Fragment>
  //     <Grid h="100vh" templateColumns="repeat(6, 1fr)">
  //       <GridItem colSpan={isDesktopScreen ? 3 : 6}>
  //         <Container maxW="container.sm" py="8">
  //           <Link href="https://www.utopiahomes.us/">
  //             <Image
  //               objectFit="cover"
  //               w="150px"
  //               src="https://i.imgur.com/avkbaJm.png"
  //               alt="Utopia Logo"
  //             />
  //           </Link>
  //         </Container>
  //         <Container maxW="md" py="8">
  //           <Stack spacing="7">
  //             <Box>
  //               <Heading mb="2">Forgot you Password?</Heading>
  //               <Text fontSize="md" as="b" color="gray.600">
  //                 Provide your account email
  //               </Text>
  //             </Box>
  //             <Stack spacing="7">
  //               <FormControl>
  //                 <FormLabel color="gray">Email</FormLabel>
  //                 <Input borderWidth="2px" size="lg" type="email" />
  //               </FormControl>
  //               <Button size="lg">Sent Reset Email</Button>
  //             </Stack>
  //             <Flex align="center" justify="center">
  //               <Text fontSize="md" as="b" mr="3">
  //                 Know your password?
  //               </Text>
  //               <Link
  //                 fontSize="md"
  //                 as="b"
  //                 color="blue.500"
  //                 onClick={() => {
  //                   navigate('/login');
  //                 }}
  //               >
  //                 Log In
  //               </Link>
  //             </Flex>
  //           </Stack>
  //         </Container>
  //       </GridItem>
  //       {isDesktopScreen && (
  //         <GridItem colSpan={3}>
  //           <Image
  //             src="https://i.imgur.com/ylWhc0T.jpg"
  //             objectFit="cover"
  //             h="100vh"
  //           />
  //         </GridItem>
  //       )}
  //     </Grid>
  //   </Fragment>
  // );
};

export default ResetPassword;
