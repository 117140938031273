import axios from 'axios';
import { Timestamp } from 'firebase/firestore';
import React, { Fragment, useEffect, useState } from 'react';
import { useReward } from 'react-rewards';
import {
  capitalizeFirstLetter,
  getFormattedDate,
  isEmptyString,
  numberWithCommas,
} from '../../../../utils/generalFunctions';

// context
import { useAgentAuth } from '../../../../context/AgentContext';
import { useAuth } from '../../../../context/AuthContext';
import { useCoapplicant } from '../../../../context/CoapplicantContext';
import { useHomeBuyerAuth } from '../../../../context/HomebuyerContext';

// chakra ui
import { Center, useToast } from '@chakra-ui/react';

// components
import HomebuyerStepEight from './HomebuyerStepEight';
import HomebuyerStepEighteen from './HomebuyerStepEighteen';
import HomebuyerStepEleven from './HomebuyerStepEleven';
import HomebuyerStepFifteen from './HomebuyerStepFifteen';
import HomebuyerStepFive from './HomebuyerStepFive';
import HomebuyerStepFour from './HomebuyerStepFour';
import HomebuyerStepFourteen from './HomebuyerStepFourteen';
import HomebuyerStepNine from './HomebuyerStepNine';
import HomebuyerStepOne from './HomebuyerStepOne';
import HomebuyerStepSeven from './HomebuyerStepSeven';
import HomebuyerStepSeventeen from './HomebuyerStepSeventeen';
import HomebuyerStepSix from './HomebuyerStepSix';
import HomebuyerStepSixteen from './HomebuyerStepSixteen';
import HomebuyerStepTen from './HomebuyerStepTen';
import HomebuyerStepThirteen from './HomebuyerStepThirteen';
import HomebuyerStepThree from './HomebuyerStepThree';
import HomebuyerStepTwelve from './HomebuyerStepTwelve';
import HomebuyerStepTwo from './HomebuyerStepTwo';

const HomebuyerApplication = ({ setIsLargeContainer }) => {
  const toast = useToast();
  const { reward, isAnimating } = useReward('rewardId', 'confetti', {
    elementCount: 600,
  });

  const {
    authUser,
    updateUserFields,
    setAuthLoading,
    agentInfo,
    getAgentByEmail,
    fetchUserAgentDetails,
  } = useAuth();
  const {
    updateApplicationFields,
    applicationData,
    handleAppFinished,
    handleSubmitApplication,
    handleSubmitDocuments,
    updateInvitationStatus,
  } = useHomeBuyerAuth();
  const { updateInvitationStatusToRejected } = useAgentAuth();
  const { createCoapplicant } = useCoapplicant();

  const [activeStep, setActiveStep] = useState(1);
  const [buttonLoading, setButtonLoading] = useState(false);

  // form answers
  const [stepOnePhoneNumber, setStepOnePhoneNumber] = useState('');

  const [stepTwoMovePlan, setStepTwoMovePlan] = useState('');

  const [stepThreeState, setStepThreeState] = useState('');
  const [stepThreeMetroArea, setStepThreeMetroArea] = useState('');
  const [stepThreeCities, setStepThreeCities] = useState('');

  const [stepFourRealEstateAgent, setStepFourRealEstateAgent] = useState('');
  const [
    stepFourRealEstateAgentFirstName,
    setStepFourRealEstateAgentFirstName,
  ] = useState('');

  const [stepFourRealEstateAgentLastName, setStepFourRealEstateAgentLastName] =
    useState('');
  const [stepFourRealEstateAgentEmail, setStepFourRealEstateAgentEmail] =
    useState('');
  const [stepFourRealEstateAgentPhone, setStepFourRealEstateAgentPhone] =
    useState('');
  const [
    stepFourConnectToRealEstateAgent,
    setStepFourConnectToRealEstateAgent,
  ] = useState('');

  const [stepFiveCoApplicant, setStepFiveCoApplicant] = useState('');
  const [stepFiveCoApplicantFirstName, setStepFiveCoApplicantFirstName] =
    useState('');
  const [stepFiveCoApplicantLastName, setStepFiveCoApplicantLastName] =
    useState('');
  const [stepFiveCoApplicantEmail, setStepFiveCoApplicantEmail] = useState('');
  const [stepFiveCoApplicantPhone, setStepFiveCoApplicantPhone] = useState('');

  const [stepSixMonthlyIncome, setStepSixMonthlyIncome] = useState('');
  const [stepSixRent, setStepSixRent] = useState('');
  const [stepSixHomePreferredPrice, setStepSixHomePreferredPrice] =
    useState('');
  const [stepSixDownPaymentSavings, setStepSixDownPaymentSavings] =
    useState('');
  const [stepSixCoApplicantMonthlyIncome, setStepSixCoApplicantMonthlyIncome] =
    useState('');

  const [stepSevenCreditFirstName, setStepSevenCreditFirstName] = useState('');
  const [stepSevenCreditMiddleName, setStepSevenCreditMiddleName] =
    useState('');
  const [stepSevenCreditLastName, setStepSevenCreditLastName] = useState('');
  const [stepSevenCreditStreetAddress, setStepSevenCreditStreetAddress] =
    useState('');
  const [stepSevenCreditZipCode, setStepSevenCreditZipCode] = useState('');
  const [stepSevenCreditSsn, setStepSevenCreditSsn] = useState('');

  const [stepEightPrequalifiedAmount, setStepEightPrequalifiedAmount] =
    useState('');

  const [stepNinePrimaryIncome, setStepNinePrimaryIncome] = useState('');

  const [stepTenEmployerName, setStepTenEmployerName] = useState('');
  const [stepTenStartDate, setStepTenStartDate] = useState('');
  const [stepTenEmployerCity, setStepTenEmployerCity] = useState('');
  const [stepTenEmployerState, setStepTenEmployerState] = useState('');

  const [stepElevenPayPeriod, setStepElevenPayPeriod] = useState('');
  const [stepElevenPreTaxIncome, setStepElevenPreTaxIncome] = useState('');

  const [stepTwelveIncomeDoc, setStepTwelveIncomeDoc] = useState('');
  const [stepTwelveIncomeDocOne, setStepTwelveIncomeDocOne] = useState('');
  const [stepTwelveIncomeDocTwo, setStepTwelveIncomeDocTwo] = useState('');
  const [stepTwelveIncomeDocThree, setStepTwelveIncomeDocThree] = useState('');

  const [stepThirteenBankStatementOne, setStepThirteenBankStatementOne] =
    useState('');
  const [stepThirteenBankStatementTwo, setStepThirteenBankStatementTwo] =
    useState('');
  const [stepThirteenBankStatementThree, setStepThirteenBankStatementThree] =
    useState('');

  const [stepFourteenHasRaise, setStepFourteenHasRaise] = useState('');

  const [stepFifteenMoreThanOneSalary, setStepFifteenMoreThanOneSalary] =
    useState('');
  const [
    stepFifteenAdditionalYearlySalary,
    setStepFifteenAdditionalYearlySalary,
  ] = useState('');

  const [stepSixteenIdentityDoc, setStepSixteenIdentityDoc] = useState('');

  const [
    stepSeventeenBackgroundCheckEviction,
    setStepSeventeenBackgroundCheckEviction,
  ] = useState('');
  const [stepSeventeenBackgroundCheckDOB, setStepSeventeenBackgroundCheckDOB] =
    useState('');

  const [stepEighteenReviewApp, setStepEighteenReviewApp] = useState('');

  const handleNext = async () => {
    try {
      setButtonLoading(true);

      let payload = {
        uid: authUser.uid,
      };

      let updateUser = false;

      if (activeStep === 1) {
        payload = {
          uid: authUser.uid,
          stepOne: [
            {
              question: 'What is your phone number?',
              answer: stepOnePhoneNumber,
            },
          ],
          currentStep: 1,
          applicationCompleted: false,
        };

        await updateUserFields({
          applicationStarted: true,
          applicationStartDate: Timestamp.now(),
        });

        await updateApplicationFields({
          applicationStarted: true,
          applicationStartDate: Timestamp.now(),
          email: authUser.email,
          isDeleted: false,
          status: 'started',
        });
      } else if (activeStep === 2) {
        payload = {
          stepTwo: [
            {
              question: 'When do you plan to move?',
              answer: stepTwoMovePlan,
            },
          ],
          currentStep: 2,
        };
      } else if (activeStep === 3) {
        payload = {
          stepThree: [
            {
              question: 'Where do you plan to live? Metro Area:',
              answer: stepThreeMetroArea,
            },
            {
              question: 'Where do you plan to live? Cities:',
              answer: stepThreeCities,
            },
            {
              question: 'Where do you plan to live? State:',
              answer: stepThreeState,
            },
          ],
          currentStep: 3,
        };
      } else if (activeStep === 4) {
        payload = {
          stepFour: [
            {
              question: 'Are you currently working with a real estate agent?',
              answer: stepFourRealEstateAgent,
            },
            {
              question:
                'Please enter the details of your real estate agent - First Name:',
              answer: stepFourRealEstateAgentFirstName ?? '',
            },
            {
              question:
                'Please enter the details of your real estate agent - Last Name:',
              answer: stepFourRealEstateAgentLastName ?? '',
            },
            {
              question:
                'Please enter the details of your real estate agent - Email:',
              answer: stepFourRealEstateAgentEmail ?? '',
            },
            {
              question:
                'Please enter the details of your real estate agent - Phone Number:',
              answer: stepFourRealEstateAgentPhone ?? '',
            },
            {
              question:
                'Can we connect you with our Partner real estate agent?',
              answer: stepFourConnectToRealEstateAgent,
            },
          ],
          currentStep: 4,
        };
      } else if (activeStep === 5) {
        payload = {
          stepFive: [
            {
              question: 'Do you have a Co-Buyer?',
              answer: stepFiveCoApplicant,
            },
            {
              question:
                'Please enter the details of your Co-Buyer - First Name:',
              answer: stepFiveCoApplicantFirstName,
            },
            {
              question:
                'Please enter the details of your Co-Buyer - Last Name:',
              answer: stepFiveCoApplicantLastName,
            },
            {
              question: 'Please enter the details of your Co-Buyer - Email:',
              answer: stepFiveCoApplicantEmail,
            },
            {
              question:
                'Please enter the details of your Co-Buyer - Phone Number:',
              answer: stepFiveCoApplicantPhone,
            },
          ],
          currentStep: 5,
        };
      } else if (activeStep === 6) {
        payload = {
          stepSix: [
            {
              question: 'Your gross monthly income:',
              answer: stepSixMonthlyIncome,
            },
            {
              question: 'Your rent right now:',
              answer: stepSixRent,
            },
            {
              question: 'Your preferred price for your future home:',
              answer: stepSixHomePreferredPrice,
            },
            {
              question: 'Your available savings for a down payment:',
              answer: stepSixDownPaymentSavings,
            },
            {
              question:
                'Please enter the details of your Co-Buyer - Gross Monthly Income:',
              answer: stepSixCoApplicantMonthlyIncome,
            },
          ],
          currentStep: 6,
        };
      } else if (activeStep === 7) {
        payload = {
          stepSeven: [
            {
              question: 'Credit verification - Legal First Name:',
              answer: stepSevenCreditFirstName,
            },
            {
              question: 'Credit verification - Legal Middle Name:',
              answer: stepSevenCreditMiddleName,
            },
            {
              question: 'Credit verification - Legal Last Name:',
              answer: stepSevenCreditLastName,
            },
            {
              question: 'Credit verification - Street Address:',
              answer: stepSevenCreditStreetAddress,
            },
            {
              question: 'Credit verification - Zip Code:',
              answer: stepSevenCreditZipCode,
            },
            {
              question: 'Credit verification - Social Security:',
              answer: stepSevenCreditSsn,
            },
          ],
          currentStep: 7,
        };
      } else if (activeStep === 8) {
        payload = {
          stepEight: [
            {
              question: 'Your pre-qualified amount',
              answer: stepEightPrequalifiedAmount,
            },
          ],
          currentStep: 8,
        };
      } else if (activeStep === 9) {
        payload = {
          stepNine: [
            {
              question: 'Your primary income',
              answer: stepNinePrimaryIncome,
            },
          ],
          currentStep: 9,
        };
      } else if (activeStep === 10) {
        payload = {
          stepTen: [
            {
              question: 'Employer details - Employer name:',
              answer: stepTenEmployerName,
            },
            {
              question: 'Employer details - Start date:',
              answer: stepTenStartDate,
            },
            {
              question: 'Employer details - Employer location - City:',
              answer: stepTenEmployerCity,
            },
            {
              question: 'Employer details - Employer location - State:',
              answer: stepTenEmployerState,
            },
          ],
          currentStep: 10,
        };
      } else if (activeStep === 11) {
        payload = {
          stepEleven: [
            {
              question: 'Average pre-tax income per paycheck:',
              answer: stepElevenPayPeriod,
            },
            {
              question: 'Enter pre-tax income per paycheck:',
              answer: stepElevenPreTaxIncome,
            },
          ],
          currentStep: 11,
        };
      } else if (activeStep === 12) {
      } else if (activeStep === 13) {
      } else if (activeStep === 14) {
        payload = {
          stepFourteen: [
            {
              question: 'Have you received a raise within the last 6 months?',
              answer: stepFourteenHasRaise,
            },
          ],
          currentStep: 14,
        };
      } else if (activeStep === 15) {
        payload = {
          stepFifteen: [
            {
              question:
                'Do you receive paychecks from more than one salaried or hourly job?',
              answer: stepFifteenMoreThanOneSalary,
            },
            {
              question: 'Your additional yearly salary:',
              answer: stepFifteenAdditionalYearlySalary,
            },
          ],
          currentStep: 15,
        };
      } else if (activeStep === 16) {
      } else if (activeStep === 17) {
        payload = {
          stepSeventeen: [
            {
              question:
                'Background Check - Has an eviction been filed against you within the last 12 months?',
              answer: stepSeventeenBackgroundCheckEviction,
            },
            {
              question: 'Background Check - Date of birth',
              answer: stepSeventeenBackgroundCheckDOB,
            },
          ],
          currentStep: 17,
          applicationCompleted: false,
        };
      } else if (activeStep === 18) {
        payload = {
          stepOne: [
            {
              question: 'What is your phone number?',
              answer: stepOnePhoneNumber,
            },
          ],
          stepTwo: [
            {
              question: 'When do you plan to move?',
              answer: stepTwoMovePlan,
            },
          ],
          stepThree: [
            {
              question: 'Where do you plan to live? Metro Area:',
              answer: stepThreeMetroArea,
            },
            {
              question: 'Where do you plan to live? Cities:',
              answer: stepThreeCities,
            },
            {
              question: 'Where do you plan to live? State:',
              answer: stepThreeState,
            },
          ],
          stepFour: [
            {
              question: 'Are you currently working with a real estate agent?',
              answer: stepFourRealEstateAgent,
            },
            {
              question:
                'Please enter the details of your real estate agent - First Name:',
              answer: stepFourRealEstateAgentFirstName ?? '',
            },
            {
              question:
                'Please enter the details of your real estate agent - Last Name:',
              answer: stepFourRealEstateAgentLastName ?? '',
            },
            {
              question:
                'Please enter the details of your real estate agent - Email:',
              answer: stepFourRealEstateAgentEmail ?? '',
            },
            {
              question:
                'Please enter the details of your real estate agent - Phone Number:',
              answer: stepFourRealEstateAgentPhone ?? '',
            },
            {
              question:
                'Can we connect you with our Partner real estate agent?',
              answer: stepFourConnectToRealEstateAgent,
            },
          ],
          stepFive: [
            {
              question: 'Do you have a Co-Buyer?',
              answer: stepFiveCoApplicant,
            },
            {
              question:
                'Please enter the details of your Co-Buyer - First Name:',
              answer: stepFiveCoApplicantFirstName,
            },
            {
              question:
                'Please enter the details of your Co-Buyer - Last Name:',
              answer: stepFiveCoApplicantLastName,
            },
            {
              question: 'Please enter the details of your Co-Buyer - Email:',
              answer: stepFiveCoApplicantEmail,
            },
            {
              question:
                'Please enter the details of your Co-Buyer - Phone Number:',
              answer: stepFiveCoApplicantPhone,
            },
          ],
          stepSix: [
            {
              question: 'Your gross monthly income:',
              answer: stepSixMonthlyIncome,
            },
            {
              question: 'Your rent right now:',
              answer: stepSixRent,
            },
            {
              question: 'Your preferred price for your future home:',
              answer: stepSixHomePreferredPrice,
            },
            {
              question: 'Your available savings for a down payment:',
              answer: stepSixDownPaymentSavings,
            },
            {
              question:
                'Please enter the details of your Co-Buyer - Gross Monthly Income:',
              answer: stepSixCoApplicantMonthlyIncome,
            },
          ],
          stepSeven: [
            {
              question: 'Credit verification - Legal First Name:',
              answer: stepSevenCreditFirstName,
            },
            {
              question: 'Credit verification - Legal Middle Name:',
              answer: stepSevenCreditMiddleName,
            },
            {
              question: 'Credit verification - Legal Last Name:',
              answer: stepSevenCreditLastName,
            },
            {
              question: 'Credit verification - Street Address:',
              answer: stepSevenCreditStreetAddress,
            },
            {
              question: 'Credit verification - Zip Code:',
              answer: stepSevenCreditZipCode,
            },
            {
              question: 'Credit verification - Social Security:',
              answer: stepSevenCreditSsn,
            },
          ],
          stepEight: [
            {
              question: 'Your pre-qualified amount',
              answer: stepEightPrequalifiedAmount,
            },
          ],
          stepNine: [
            {
              question: 'Your primary income',
              answer: stepNinePrimaryIncome,
            },
          ],
          stepTen: [
            {
              question: 'Employer details - Employer name:',
              answer: stepTenEmployerName,
            },
            {
              question: 'Employer details - Start date:',
              answer: stepTenStartDate,
            },
            {
              question: 'Employer details - Employer location - City:',
              answer: stepTenEmployerCity,
            },
            {
              question: 'Employer details - Employer location - State:',
              answer: stepTenEmployerState,
            },
          ],
          stepEleven: [
            {
              question: 'Average pre-tax income per paycheck:',
              answer: stepElevenPayPeriod,
            },
            {
              question: 'Enter pre-tax income per paycheck:',
              answer: stepElevenPreTaxIncome,
            },
          ],
          stepFourteen: [
            {
              question: 'Have you received a raise within the last 6 months?',
              answer: stepFourteenHasRaise,
            },
          ],
          stepFifteen: [
            {
              question:
                'Do you receive paychecks from more than one salaried or hourly job?',
              answer: stepFifteenMoreThanOneSalary,
            },
            {
              question: 'Your additional yearly salary:',
              answer: stepFifteenAdditionalYearlySalary,
            },
          ],
          stepSeventeen: [
            {
              question:
                'Background Check - Has an eviction been filed against you within the last 12 months?',
              answer: stepSeventeenBackgroundCheckEviction,
            },
            {
              question: 'Background Check - Date of birth',
              answer: stepSeventeenBackgroundCheckDOB,
            },
          ],
          currentStep: 18,
          applicationCompleted: true,
          addCoBuyerAfterSubmission: false,
          timeSubmitted: Timestamp.now(),
        };
        updateUser = true;
      }

      await updateApplicationFields(payload);

      if (updateUser) {
        setAuthLoading(true);
        updateInvitationStatus(authUser.email?.toLowerCase(), 'joined');

        let updatePayload = {
          applicationCompleted: true,
          addCoBuyerAfterSubmission: false,
        };

        const agentDetails = await getAgentByEmail(
          stepFourRealEstateAgentEmail?.toLowerCase()
        );

        if (agentDetails) {
          updatePayload.agent = agentDetails.uid;
        }

        try {
          await updateInvitationStatusToRejected(
            authUser.email,
            agentDetails ? agentDetails.uid : ''
          );
        } catch (err) {
          console.log(
            '🚀 ~ file: HomebuyerApplication.js:256 ~ handleNext ~ err',
            err
          );
        }

        await updateUserFields(updatePayload);

        // if agent added
        if (
          stepFourRealEstateAgent == 'yes' &&
          stepFourRealEstateAgentFirstName &&
          stepFourRealEstateAgentLastName &&
          stepFourRealEstateAgentEmail &&
          stepFourRealEstateAgentEmail?.toLowerCase() !==
            agentInfo?.email?.toLowerCase
        ) {
          await axios({
            method: 'post',
            url: `${`https://utopia-server.onrender.com`}/api/v1/email/agent_email_from_user`,
            headers: {
              'Content-Type': 'application/json',
            },
            data: JSON.stringify({
              agentFirstName: capitalizeFirstLetter(
                stepFourRealEstateAgentFirstName
              ),
              agentLastName: capitalizeFirstLetter(
                stepFourRealEstateAgentLastName
              ),
              userFirstName: capitalizeFirstLetter(stepSevenCreditFirstName),
              userLastName: capitalizeFirstLetter(stepSevenCreditLastName),
              submissionDate: getFormattedDate(
                new Date(Timestamp.now().seconds * 1000)
              ),
              emailTo: stepFourRealEstateAgentEmail,
            }),
          });
          console.log('agent email sent');
        }
        // if coapplicant added
        if (
          stepFiveCoApplicant == 'yes' &&
          stepFiveCoApplicantFirstName &&
          stepFiveCoApplicantLastName &&
          stepFiveCoApplicantEmail
        ) {
          const coapplicantId = await createCoapplicant(
            capitalizeFirstLetter(stepFiveCoApplicantFirstName),
            capitalizeFirstLetter(stepFiveCoApplicantLastName),
            stepFiveCoApplicantEmail,
            authUser.uid,
            capitalizeFirstLetter(stepSevenCreditFirstName),
            capitalizeFirstLetter(stepSevenCreditLastName),
            authUser.email
          );
          await axios({
            method: 'post',
            url: `${`https://utopia-server.onrender.com`}/api/v1/email/coapplicant_email_from_user`,
            headers: {
              'Content-Type': 'application/json',
            },
            data: JSON.stringify({
              coapplicantFirstName: capitalizeFirstLetter(
                stepFiveCoApplicantFirstName
              ),
              coapplicantLastName: capitalizeFirstLetter(
                stepFiveCoApplicantLastName
              ),
              userFirstName: capitalizeFirstLetter(stepSevenCreditFirstName),
              userLastName: capitalizeFirstLetter(stepSevenCreditLastName),
              submissionDate: getFormattedDate(
                new Date(Timestamp.now().seconds * 1000)
              ),
              emailTo: stepFiveCoApplicantEmail,
              coapplicantLink: `${process.env.REACT_APP_WEB_URL}/coapplicant/onboarding/${coapplicantId}`,
            }),
          });
          console.log('agent email sent');
        }

        setButtonLoading(false);
        window.location.reload(false);
      }

      setActiveStep(activeStep + 1);

      if (activeStep + 1 === 8) {
        reward();
      }

      setButtonLoading(false);
    } catch (err) {
      setButtonLoading(false);
      toast({
        title: 'Error',
        description: 'Something went wrong. Please try again',
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const setInitialData = async () => {
    try {
      if (applicationData) {
        if (applicationData.stepOne) {
          setStepOnePhoneNumber(applicationData.stepOne[0]?.answer);
        }

        if (applicationData.stepTwo) {
          setStepTwoMovePlan(applicationData.stepTwo[0]?.answer);
        }

        if (applicationData.stepThree) {
          setStepThreeMetroArea(applicationData.stepThree[0]?.answer);
          setStepThreeCities(applicationData.stepThree[1]?.answer);
          setStepThreeState(applicationData.stepThree[2]?.answer);
        }

        if (applicationData.stepFour) {
          setStepFourRealEstateAgent(applicationData.stepFour[0]?.answer);
          setStepFourRealEstateAgentFirstName(
            applicationData.stepFour[1]?.answer
          );
          setStepFourRealEstateAgentLastName(
            applicationData.stepFour[2]?.answer
          );
          setStepFourRealEstateAgentEmail(applicationData.stepFour[3]?.answer);
          setStepFourRealEstateAgentPhone(applicationData.stepFour[4]?.answer);
          setStepFourConnectToRealEstateAgent(
            applicationData.stepFour[5]?.answer
          );
        }

        if (applicationData.stepFive) {
          setStepFiveCoApplicant(applicationData.stepFive[0]?.answer);
          setStepFiveCoApplicantFirstName(applicationData.stepFive[1]?.answer);
          setStepFiveCoApplicantLastName(applicationData.stepFive[2]?.answer);
          setStepFiveCoApplicantEmail(applicationData.stepFive[3]?.answer);
          setStepFiveCoApplicantPhone(applicationData.stepFive[4]?.answer);
        }

        if (applicationData.stepSix) {
          setStepSixMonthlyIncome(applicationData.stepSix[0]?.answer);
          setStepSixRent(applicationData.stepSix[1]?.answer);
          setStepSixHomePreferredPrice(applicationData.stepSix[2]?.answer);
          setStepSixDownPaymentSavings(applicationData.stepSix[3]?.answer);
          setStepSixCoApplicantMonthlyIncome(
            applicationData.stepSix[4]?.answer
          );
        }

        if (applicationData.stepSeven) {
          setStepSevenCreditFirstName(applicationData.stepSeven[0]?.answer);
          setStepSevenCreditMiddleName(applicationData.stepSeven[1]?.answer);
          setStepSevenCreditLastName(applicationData.stepSeven[2]?.answer);
          setStepSevenCreditStreetAddress(applicationData.stepSeven[3]?.answer);
          setStepSevenCreditZipCode(applicationData.stepSeven[4]?.answer);
          setStepSevenCreditSsn(applicationData.stepSeven[5]?.answer);
        }

        if (applicationData.stepEight) {
          setStepEightPrequalifiedAmount(applicationData.stepEight[0]?.answer);
        }

        if (applicationData.stepNine) {
          setStepNinePrimaryIncome(applicationData.stepNine[0]?.answer);
        }

        if (applicationData.stepTen) {
          setStepTenEmployerName(applicationData.stepTen[0]?.answer);
          setStepTenStartDate(applicationData.stepTen[1]?.answer);
          setStepTenEmployerCity(applicationData.stepTen[2]?.answer);
          setStepTenEmployerState(applicationData.stepTen[3]?.answer);
        }

        if (applicationData.stepEleven) {
          setStepElevenPayPeriod(applicationData.stepEleven[0]?.answer);
          setStepElevenPreTaxIncome(applicationData.stepEleven[1]?.answer);
        }

        if (applicationData.stepFourteen) {
          setStepFourteenHasRaise(applicationData.stepFourteen[0]?.answer);
        }

        if (applicationData.stepFifteen) {
          setStepFifteenMoreThanOneSalary(
            applicationData.stepFifteen[0]?.answer
          );
          setStepFifteenAdditionalYearlySalary(
            applicationData.stepFifteen[1]?.answer
          );
        }

        if (applicationData.stepSeventeen) {
          setStepSeventeenBackgroundCheckEviction(
            applicationData.stepSeventeen[0]?.answer
          );
          setStepSeventeenBackgroundCheckDOB(
            applicationData.stepSeventeen[1]?.answer
          );
        }
      }
    } catch (err) {
      console.log('setInitialData() ERROR: ', err);
    }
  };

  useEffect(() => {
    if (stepFiveCoApplicant === 'yes') {
      if (
        !isEmptyString(stepSixMonthlyIncome) ||
        !isEmptyString(stepSixCoApplicantMonthlyIncome)
      ) {
        const annualIncome =
          (parseInt(stepSixMonthlyIncome) +
            parseInt(stepSixCoApplicantMonthlyIncome)) *
          12;
        const prequalifiedAmount = Math.min(4.358 * annualIncome, 498990);
        setStepEightPrequalifiedAmount(
          numberWithCommas(parseInt(prequalifiedAmount))
        );
      }
    } else {
      if (!isEmptyString(stepSixMonthlyIncome)) {
        const annualIncome = parseInt(stepSixMonthlyIncome) * 12;
        const prequalifiedAmount = Math.min(4.358 * annualIncome, 498990);
        setStepEightPrequalifiedAmount(
          numberWithCommas(parseInt(prequalifiedAmount))
        );
      }
    }
  }, [
    stepFiveCoApplicant,
    stepSixCoApplicantMonthlyIncome,
    stepSixMonthlyIncome,
  ]);

  useEffect(() => {
    setInitialData();
    fetchUserAgentDetails(authUser?.data);
  }, [applicationData]);

  const renderStep = activeStep => {
    switch (activeStep) {
      case 1:
        return (
          <HomebuyerStepOne
            setActiveStep={setActiveStep}
            stepOnePhoneNumber={stepOnePhoneNumber}
            setStepOnePhoneNumber={setStepOnePhoneNumber}
            handleNext={handleNext}
            loading={buttonLoading}
          />
        );
      case 2:
        return (
          <HomebuyerStepTwo
            setActiveStep={setActiveStep}
            stepTwoMovePlan={stepTwoMovePlan}
            setStepTwoMovePlan={setStepTwoMovePlan}
            handleNext={handleNext}
            loading={buttonLoading}
          />
        );
      case 3:
        return (
          <HomebuyerStepThree
            setActiveStep={setActiveStep}
            stepThreeState={stepThreeState}
            setStepThreeState={setStepThreeState}
            stepThreeMetroArea={stepThreeMetroArea}
            setStepThreeMetroArea={setStepThreeMetroArea}
            stepThreeCities={stepThreeCities}
            setStepThreeCities={setStepThreeCities}
            handleNext={handleNext}
            loading={buttonLoading}
            updateApplicationFields={updateApplicationFields}
          />
        );
      case 4:
        return (
          <HomebuyerStepFour
            setActiveStep={setActiveStep}
            stepFourRealEstateAgent={stepFourRealEstateAgent}
            setStepFourRealEstateAgent={setStepFourRealEstateAgent}
            stepFourRealEstateAgentFirstName={stepFourRealEstateAgentFirstName}
            setStepFourRealEstateAgentFirstName={
              setStepFourRealEstateAgentFirstName
            }
            stepFourRealEstateAgentLastName={stepFourRealEstateAgentLastName}
            setStepFourRealEstateAgentLastName={
              setStepFourRealEstateAgentLastName
            }
            stepFourRealEstateAgentEmail={stepFourRealEstateAgentEmail}
            setStepFourRealEstateAgentEmail={setStepFourRealEstateAgentEmail}
            stepFourRealEstateAgentPhone={stepFourRealEstateAgentPhone}
            setStepFourRealEstateAgentPhone={setStepFourRealEstateAgentPhone}
            stepFourConnectToRealEstateAgent={stepFourConnectToRealEstateAgent}
            setStepFourConnectToRealEstateAgent={
              setStepFourConnectToRealEstateAgent
            }
            applicationData={applicationData}
            handleNext={handleNext}
            loading={buttonLoading}
          />
        );
      case 5:
        return (
          <HomebuyerStepFive
            setActiveStep={setActiveStep}
            stepFiveCoApplicant={stepFiveCoApplicant}
            setStepFiveCoApplicant={setStepFiveCoApplicant}
            stepFiveCoApplicantFirstName={stepFiveCoApplicantFirstName}
            setStepFiveCoApplicantFirstName={setStepFiveCoApplicantFirstName}
            stepFiveCoApplicantLastName={stepFiveCoApplicantLastName}
            setStepFiveCoApplicantLastName={setStepFiveCoApplicantLastName}
            stepFiveCoApplicantEmail={stepFiveCoApplicantEmail}
            setStepFiveCoApplicantEmail={setStepFiveCoApplicantEmail}
            stepFiveCoApplicantPhone={stepFiveCoApplicantPhone}
            setStepFiveCoApplicantPhone={setStepFiveCoApplicantPhone}
            handleNext={handleNext}
            loading={buttonLoading}
          />
        );
      case 6:
        return (
          <HomebuyerStepSix
            setActiveStep={setActiveStep}
            stepFiveCoApplicant={stepFiveCoApplicant}
            stepSixMonthlyIncome={stepSixMonthlyIncome}
            setStepSixMonthlyIncome={setStepSixMonthlyIncome}
            stepSixRent={stepSixRent}
            setStepSixRent={setStepSixRent}
            stepSixHomePreferredPrice={stepSixHomePreferredPrice}
            setStepSixHomePreferredPrice={setStepSixHomePreferredPrice}
            stepSixDownPaymentSavings={stepSixDownPaymentSavings}
            setStepSixDownPaymentSavings={setStepSixDownPaymentSavings}
            stepSixCoApplicantMonthlyIncome={stepSixCoApplicantMonthlyIncome}
            setStepSixCoApplicantMonthlyIncome={
              setStepSixCoApplicantMonthlyIncome
            }
            handleNext={handleNext}
            loading={buttonLoading}
          />
        );
      case 7:
        return (
          <HomebuyerStepSeven
            setActiveStep={setActiveStep}
            stepSevenCreditFirstName={stepSevenCreditFirstName}
            setStepSevenCreditFirstName={setStepSevenCreditFirstName}
            stepSevenCreditMiddleName={stepSevenCreditMiddleName}
            setStepSevenCreditMiddleName={setStepSevenCreditMiddleName}
            stepSevenCreditLastName={stepSevenCreditLastName}
            setStepSevenCreditLastName={setStepSevenCreditLastName}
            stepSevenCreditStreetAddress={stepSevenCreditStreetAddress}
            setStepSevenCreditStreetAddress={setStepSevenCreditStreetAddress}
            stepSevenCreditZipCode={stepSevenCreditZipCode}
            setStepSevenCreditZipCode={setStepSevenCreditZipCode}
            stepSevenCreditSsn={stepSevenCreditSsn}
            setStepSevenCreditSsn={setStepSevenCreditSsn}
            handleNext={handleNext}
            loading={buttonLoading}
            reward={reward}
          />
        );
      case 8:
        return (
          <HomebuyerStepEight
            setActiveStep={setActiveStep}
            stepEightPrequalifiedAmount={stepEightPrequalifiedAmount}
            setStepEightPrequalifiedAmount={setStepEightPrequalifiedAmount}
            handleNext={handleNext}
            loading={buttonLoading}
            isAnimating={isAnimating}
          />
        );
      case 9:
        return (
          <HomebuyerStepNine
            setActiveStep={setActiveStep}
            stepNinePrimaryIncome={stepNinePrimaryIncome}
            setStepNinePrimaryIncome={setStepNinePrimaryIncome}
            handleNext={handleNext}
            loading={buttonLoading}
          />
        );
      case 10:
        return (
          <HomebuyerStepTen
            setActiveStep={setActiveStep}
            stepTenEmployerName={stepTenEmployerName}
            setStepTenEmployerName={setStepTenEmployerName}
            stepTenStartDate={stepTenStartDate}
            setStepTenStartDate={setStepTenStartDate}
            stepTenEmployerCity={stepTenEmployerCity}
            setStepTenEmployerCity={setStepTenEmployerCity}
            stepTenEmployerState={stepTenEmployerState}
            setStepTenEmployerState={setStepTenEmployerState}
            handleNext={handleNext}
            loading={buttonLoading}
          />
        );
      case 11:
        return (
          <HomebuyerStepEleven
            setActiveStep={setActiveStep}
            stepTenEmployerName={stepTenEmployerName}
            stepElevenPayPeriod={stepElevenPayPeriod}
            setStepElevenPayPeriod={setStepElevenPayPeriod}
            stepElevenPreTaxIncome={stepElevenPreTaxIncome}
            setStepElevenPreTaxIncome={setStepElevenPreTaxIncome}
            handleNext={handleNext}
            loading={buttonLoading}
          />
        );
      case 12:
        return (
          <HomebuyerStepTwelve
            setActiveStep={setActiveStep}
            stepNinePrimaryIncome={stepNinePrimaryIncome}
            stepTwelveIncomeDoc={stepTwelveIncomeDoc}
            setStepTwelveIncomeDoc={setStepTwelveIncomeDoc}
            stepTwelveIncomeDocOne={stepTwelveIncomeDocOne}
            setStepTwelveIncomeDocOne={setStepTwelveIncomeDocOne}
            stepTwelveIncomeDocTwo={stepTwelveIncomeDocTwo}
            setStepTwelveIncomeDocTwo={setStepTwelveIncomeDocTwo}
            stepTwelveIncomeDocThree={stepTwelveIncomeDocThree}
            setStepTwelveIncomeDocThree={setStepTwelveIncomeDocThree}
            stepThirteenBankStatementOne={stepThirteenBankStatementOne}
            stepThirteenBankStatementTwo={stepThirteenBankStatementTwo}
            stepThirteenBankStatementThree={stepThirteenBankStatementThree}
            stepSixteenIdentityDoc={stepSixteenIdentityDoc}
            handleNext={handleNext}
            loading={buttonLoading}
          />
        );
      case 13:
        return (
          <HomebuyerStepThirteen
            setActiveStep={setActiveStep}
            stepNinePrimaryIncome={stepNinePrimaryIncome}
            stepThirteenBankStatementOne={stepThirteenBankStatementOne}
            setStepThirteenBankStatementOne={setStepThirteenBankStatementOne}
            stepThirteenBankStatementTwo={stepThirteenBankStatementTwo}
            setStepThirteenBankStatementTwo={setStepThirteenBankStatementTwo}
            stepThirteenBankStatementThree={stepThirteenBankStatementThree}
            setStepThirteenBankStatementThree={
              setStepThirteenBankStatementThree
            }
            stepTwelveIncomeDocOne={stepTwelveIncomeDocOne}
            stepTwelveIncomeDocTwo={stepTwelveIncomeDocTwo}
            stepTwelveIncomeDocThree={stepTwelveIncomeDocThree}
            stepSixteenIdentityDoc={stepSixteenIdentityDoc}
            handleNext={handleNext}
            loading={buttonLoading}
          />
        );
      case 14:
        return (
          <HomebuyerStepFourteen
            setActiveStep={setActiveStep}
            stepFourteenHasRaise={stepFourteenHasRaise}
            setStepFourteenHasRaise={setStepFourteenHasRaise}
            handleNext={handleNext}
            loading={buttonLoading}
          />
        );
      case 15:
        return (
          <HomebuyerStepFifteen
            setActiveStep={setActiveStep}
            stepFifteenMoreThanOneSalary={stepFifteenMoreThanOneSalary}
            setStepFifteenMoreThanOneSalary={setStepFifteenMoreThanOneSalary}
            stepFifteenAdditionalYearlySalary={
              stepFifteenAdditionalYearlySalary
            }
            setStepFifteenAdditionalYearlySalary={
              setStepFifteenAdditionalYearlySalary
            }
            handleNext={handleNext}
            loading={buttonLoading}
          />
        );
      case 16:
        return (
          <HomebuyerStepSixteen
            setActiveStep={setActiveStep}
            stepSixteenIdentityDoc={stepSixteenIdentityDoc}
            setStepSixteenIdentityDoc={setStepSixteenIdentityDoc}
            stepTwelveIncomeDocOne={stepTwelveIncomeDocOne}
            stepTwelveIncomeDocTwo={stepTwelveIncomeDocTwo}
            stepTwelveIncomeDocThree={stepTwelveIncomeDocThree}
            stepThirteenBankStatementOne={stepThirteenBankStatementOne}
            stepThirteenBankStatementTwo={stepThirteenBankStatementTwo}
            stepThirteenBankStatementThree={stepThirteenBankStatementThree}
            handleNext={handleNext}
            loading={buttonLoading}
          />
        );
      case 17:
        return (
          <HomebuyerStepSeventeen
            setActiveStep={setActiveStep}
            stepSeventeenBackgroundCheckEviction={
              stepSeventeenBackgroundCheckEviction
            }
            setStepSeventeenBackgroundCheckEviction={
              setStepSeventeenBackgroundCheckEviction
            }
            stepSeventeenBackgroundCheckDOB={stepSeventeenBackgroundCheckDOB}
            setStepSeventeenBackgroundCheckDOB={
              setStepSeventeenBackgroundCheckDOB
            }
            handleNext={handleNext}
            loading={buttonLoading}
          />
        );
      case 18:
        setIsLargeContainer(true);
        return (
          <HomebuyerStepEighteen
            setActiveStep={setActiveStep}
            stepOnePhoneNumber={stepOnePhoneNumber}
            setStepOnePhoneNumber={setStepOnePhoneNumber}
            stepTwoMovePlan={stepTwoMovePlan}
            setStepTwoMovePlan={setStepTwoMovePlan}
            stepThreeState={stepThreeState}
            setStepThreeState={setStepThreeState}
            stepThreeMetroArea={stepThreeMetroArea}
            setStepThreeMetroArea={setStepThreeMetroArea}
            stepThreeCities={stepThreeCities}
            setStepThreeCities={setStepThreeCities}
            stepFourRealEstateAgent={stepFourRealEstateAgent}
            setStepFourRealEstateAgent={setStepFourRealEstateAgent}
            stepFourRealEstateAgentFirstName={stepFourRealEstateAgentFirstName}
            setStepFourRealEstateAgentFirstName={
              setStepFourRealEstateAgentFirstName
            }
            stepFourRealEstateAgentLastName={stepFourRealEstateAgentLastName}
            setStepFourRealEstateAgentLastName={
              setStepFourRealEstateAgentLastName
            }
            stepFourRealEstateAgentEmail={stepFourRealEstateAgentEmail}
            setStepFourRealEstateAgentEmail={setStepFourRealEstateAgentEmail}
            stepFourRealEstateAgentPhone={stepFourRealEstateAgentPhone}
            setStepFourRealEstateAgentPhone={setStepFourRealEstateAgentPhone}
            stepFourConnectToRealEstateAgent={stepFourConnectToRealEstateAgent}
            setStepFourConnectToRealEstateAgent={
              setStepFourConnectToRealEstateAgent
            }
            stepFiveCoApplicant={stepFiveCoApplicant}
            setStepFiveCoApplicant={setStepFiveCoApplicant}
            stepFiveCoApplicantFirstName={stepFiveCoApplicantFirstName}
            setStepFiveCoApplicantFirstName={setStepFiveCoApplicantFirstName}
            stepFiveCoApplicantLastName={stepFiveCoApplicantLastName}
            setStepFiveCoApplicantLastName={setStepFiveCoApplicantLastName}
            stepFiveCoApplicantEmail={stepFiveCoApplicantEmail}
            setStepFiveCoApplicantEmail={setStepFiveCoApplicantEmail}
            stepFiveCoApplicantPhone={stepFiveCoApplicantPhone}
            setStepFiveCoApplicantPhone={setStepFiveCoApplicantPhone}
            stepSixMonthlyIncome={stepSixMonthlyIncome}
            setStepSixMonthlyIncome={setStepSixMonthlyIncome}
            stepSixRent={stepSixRent}
            setStepSixRent={setStepSixRent}
            stepSixHomePreferredPrice={stepSixHomePreferredPrice}
            setStepSixHomePreferredPrice={setStepSixHomePreferredPrice}
            stepSixDownPaymentSavings={stepSixDownPaymentSavings}
            setStepSixDownPaymentSavings={setStepSixDownPaymentSavings}
            stepSixCoApplicantMonthlyIncome={stepSixCoApplicantMonthlyIncome}
            setStepSixCoApplicantMonthlyIncome={
              setStepSixCoApplicantMonthlyIncome
            }
            stepSevenCreditFirstName={stepSevenCreditFirstName}
            setStepSevenCreditFirstName={setStepSevenCreditFirstName}
            stepSevenCreditMiddleName={stepSevenCreditMiddleName}
            setStepSevenCreditMiddleName={setStepSevenCreditMiddleName}
            stepSevenCreditLastName={stepSevenCreditLastName}
            setStepSevenCreditLastName={setStepSevenCreditLastName}
            stepSevenCreditStreetAddress={stepSevenCreditStreetAddress}
            setStepSevenCreditStreetAddress={setStepSevenCreditStreetAddress}
            stepSevenCreditZipCode={stepSevenCreditZipCode}
            setStepSevenCreditZipCode={setStepSevenCreditZipCode}
            stepSevenCreditSsn={stepSevenCreditSsn}
            setStepSevenCreditSsn={setStepSevenCreditSsn}
            stepEightPrequalifiedAmount={stepEightPrequalifiedAmount}
            setStepEightPrequalifiedAmount={setStepEightPrequalifiedAmount}
            stepNinePrimaryIncome={stepNinePrimaryIncome}
            setStepNinePrimaryIncome={setStepNinePrimaryIncome}
            stepTenEmployerName={stepTenEmployerName}
            setStepTenEmployerName={setStepTenEmployerName}
            stepTenStartDate={stepTenStartDate}
            setStepTenStartDate={setStepTenStartDate}
            stepTenEmployerCity={stepTenEmployerCity}
            setStepTenEmployerCity={setStepTenEmployerCity}
            stepTenEmployerState={stepTenEmployerState}
            setStepTenEmployerState={setStepTenEmployerState}
            stepElevenPayPeriod={stepElevenPayPeriod}
            setStepElevenPayPeriod={setStepElevenPayPeriod}
            stepElevenPreTaxIncome={stepElevenPreTaxIncome}
            setStepElevenPreTaxIncome={setStepElevenPreTaxIncome}
            stepTwelveIncomeDoc={stepTwelveIncomeDoc}
            setStepTwelveIncomeDoc={setStepTwelveIncomeDoc}
            stepTwelveIncomeDocOne={stepTwelveIncomeDocOne}
            setStepTwelveIncomeDocOne={setStepTwelveIncomeDocOne}
            stepTwelveIncomeDocTwo={stepTwelveIncomeDocTwo}
            setStepTwelveIncomeDocTwo={setStepTwelveIncomeDocTwo}
            stepTwelveIncomeDocThree={stepTwelveIncomeDocThree}
            setStepTwelveIncomeDocThree={setStepTwelveIncomeDocThree}
            stepThirteenBankStatementOne={stepThirteenBankStatementOne}
            setStepThirteenBankStatementOne={setStepThirteenBankStatementOne}
            stepThirteenBankStatementTwo={stepThirteenBankStatementTwo}
            setStepThirteenBankStatementTwo={setStepThirteenBankStatementTwo}
            stepThirteenBankStatementThree={stepThirteenBankStatementThree}
            setStepThirteenBankStatementThree={
              setStepThirteenBankStatementThree
            }
            stepFourteenHasRaise={stepFourteenHasRaise}
            setStepFourteenHasRaise={setStepFourteenHasRaise}
            stepFifteenMoreThanOneSalary={stepFifteenMoreThanOneSalary}
            setStepFifteenMoreThanOneSalary={setStepFifteenMoreThanOneSalary}
            stepFifteenAdditionalYearlySalary={
              stepFifteenAdditionalYearlySalary
            }
            setStepFifteenAdditionalYearlySalary={
              setStepFifteenAdditionalYearlySalary
            }
            stepSixteenIdentityDoc={stepSixteenIdentityDoc}
            setStepSixteenIdentityDoc={setStepSixteenIdentityDoc}
            stepSeventeenBackgroundCheckEviction={
              stepSeventeenBackgroundCheckEviction
            }
            setStepSeventeenBackgroundCheckEviction={
              setStepSeventeenBackgroundCheckEviction
            }
            stepSeventeenBackgroundCheckDOB={stepSeventeenBackgroundCheckDOB}
            setStepSeventeenBackgroundCheckDOB={
              setStepSeventeenBackgroundCheckDOB
            }
            stepEighteenReviewApp={stepEighteenReviewApp}
            setStepEighteenReviewApp={setStepEighteenReviewApp}
            authUser={authUser}
            handleAppFinished={handleAppFinished}
            handleSubmitApplication={handleSubmitApplication}
            handleNext={handleNext}
            loading={buttonLoading}
            setLoading={setButtonLoading}
            handleSubmitDocuments={handleSubmitDocuments}
          />
        );
      default:
        return (
          <HomebuyerStepOne
            setActiveStep={setActiveStep}
            stepOnePhoneNumber={stepOnePhoneNumber}
            setStepOnePhoneNumber={setStepOnePhoneNumber}
            handleNext={handleNext}
            loading={buttonLoading}
          />
        );
    }
  };

  return (
    <Fragment>
      {renderStep(activeStep)}
      <Center>
        <span id="rewardId" />
      </Center>
    </Fragment>
  );
};

export default HomebuyerApplication;
