import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  UnorderedList,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Field, Formik } from 'formik';
import { useState } from 'react';
import { useAgentAuth } from '../../../context/AgentContext';
import { getNormalizedError } from '../../../utils/generalFunctions';
import { inviteUserByEmail } from '../../../utils/services/agent.service';
import { clientInvitationSchema } from '../../../validations/ClientInviteSchema';

const AgentInvitationButton = ({ width }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [buttonLoading, setButtonLoading] = useState(false);
  const toast = useToast();
  const { getAgentInvitesData } = useAgentAuth();

  const handleSubmit = async values => {
    try {
      setButtonLoading(true);

      let payload = {
        ...values,
        client_email_address: values.client_email_address?.toLowerCase(),
        isAgent: true,
      };

      await inviteUserByEmail(payload);

      await getAgentInvitesData();

      toast({
        title: 'Agent Invited',
        description: `${values.client_name} invited successfully`,
        status: 'success',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });

      onClose();
    } catch (err) {
      const error = getNormalizedError(err);

      toast({
        title: 'Request Failed',
        description: error
          ? error
          : 'Unable to invite agent at the moment, please try again later',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setButtonLoading(false);
    }
  };

  return (
    <>
      <Button
        width={width ? width : '100%'}
        backgroundColor={'#1C2430'}
        textColor={'white'}
        minW={30}
        px={10}
        onClick={onOpen}
        _hover={{
          bg: 'black',
          cursor: 'pointer',
        }}
      >
        {'Invite Agent'}
      </Button>

      <Modal
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        size={{ base: 'full', md: 'xl' }}
        zIndex={1}
      >
        <ModalOverlay />
        <ModalContent minH="60vh">
          <ModalHeader>{'Invite Agents'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Please invite other agents to Utopia Agent Network (UAN) to earn
              referal bonus & perks of becoming a preferred agent.
            </Text>
            <Text>
              <br />
              The benefits to others agents for joining UAN are:
            </Text>
            <UnorderedList mt={2}>
              <ListItem>Earn Full Commission & incentives</ListItem>
              <ListItem>
                Close More Homes & Faster with our all cash offers
              </ListItem>
              <ListItem>
                Convert Renters who are not mortgage ready into Homeowners &
                make more money
              </ListItem>
              <ListItem>
                Expand your Customer base by getting more leads from us
              </ListItem>
            </UnorderedList>

            <Formik
              initialValues={{
                client_name: '',
                client_email_address: '',
              }}
              validationSchema={clientInvitationSchema}
              onSubmit={async values => {
                handleSubmit(values);
              }}
            >
              {formik => (
                <>
                  <FormControl
                    isInvalid={
                      formik.errors.client_name && formik.touched.client_name
                    }
                    mt={2}
                  >
                    <FormLabel>Agent's Full name</FormLabel>
                    <Field
                      as={Input}
                      borderWidth="2px"
                      id="client_name"
                      name="client_name"
                      size="md"
                      borderRadius="md"
                      disabled={buttonLoading}
                      placeholder="John Doe"
                    />
                    <FormErrorMessage>
                      {formik.errors.client_name}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      formik.errors.client_email_address &&
                      formik.touched.client_email_address
                    }
                    mt={2}
                  >
                    <FormLabel>Agent's Email Address</FormLabel>
                    <Field
                      as={Input}
                      borderWidth="2px"
                      id="client_email_address"
                      name="client_email_address"
                      size="md"
                      borderRadius="md"
                      type="email"
                      disabled={buttonLoading}
                      placeholder="agent@email.com"
                    />
                    <FormErrorMessage>
                      {formik.errors.client_email_address}
                    </FormErrorMessage>
                  </FormControl>

                  <Flex mt={'10%'} justifyContent="flex-end">
                    <Button onClick={onClose}>Cancel</Button>
                    <Button
                      onClick={() => formik.handleSubmit()}
                      backgroundColor={'#1C2430'}
                      textColor={'white'}
                      minW={30}
                      ml={2}
                      isDisabled={buttonLoading}
                      _hover={{
                        bg: 'black',
                        cursor: 'pointer',
                      }}
                    >
                      {buttonLoading ? <Spinner /> : ' Send Invite'}
                    </Button>
                  </Flex>
                </>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AgentInvitationButton;
