import { HTTP_CLIENT } from '../interceptor';

const documentsEmailToAgent = async params => {
  const response = await HTTP_CLIENT.post(
    'email/additional_docs_email',
    params
  );
  return response;
};

export { documentsEmailToAgent };
