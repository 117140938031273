import React, { Fragment, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Formik, Field } from 'formik';
import { ResetPasswordSchema } from '../validations/ResetPasswordSchema';

// chakra
import {
  Box,
  Center,
  Flex,
  useToast,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Stack,
  Button,
  Link,
  Text,
  Input,
  Image,
} from '@chakra-ui/react';
import { useAuth } from '../context/AuthContext';

// context

const AuthAction = () => {
  const toast = useToast();
  const [searchParams] = useSearchParams();

  const { handleFirebaseAction, authLoading } = useAuth();

  const [modeParam, setModeParam] = useState(null);
  const [actionCodeParam, setActionCodeParam] = useState(null);
  const [continueUrlParam, setContinueUrlParam] = useState(null);
  const [langParam, setLangParam] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const mode = searchParams.get('mode');
      const actionCode = searchParams.get('oobCode');
      const continueUrl = searchParams.get('continueUrl');
      const lang = searchParams.get('lang');

      setModeParam(mode);
      setActionCodeParam(actionCode);
      setContinueUrlParam(continueUrl);
      setLangParam(lang);

      if (mode == 'verifyEmail') {
        await handleFirebaseAction(mode, actionCode, continueUrl, lang);
      }
    };

    fetchData().catch(console.error);
  }, []);

  return (
    <Fragment>
      {modeParam != null && (
        <Box w="100%" h="100vh">
          {modeParam == 'resetPassword' && (
            <Flex direction="column" align="center" justify="center" mt="20">
              <Box boxShadow="xl" px="6" py="6" minW="500px">
                <Formik
                  initialValues={{
                    newPassword: '',
                    confirmNewPassword: '',
                  }}
                  validationSchema={ResetPasswordSchema}
                  onSubmit={async values => {
                    if (values.newPassword != values.confirmNewPassword) {
                      toast({
                        title: 'Error on Sign Up',
                        description:
                          'Password and Confirm Password must be the same',
                        status: 'error',
                        duration: 4000,
                        isClosable: true,
                        position: 'top-right',
                      });
                    } else {
                      await handleFirebaseAction(
                        modeParam,
                        actionCodeParam,
                        continueUrlParam,
                        langParam,
                        values.newPassword
                      );
                    }
                  }}
                >
                  {formik => (
                    <form onSubmit={formik.handleSubmit}>
                      <Box>
                        <FormControl mb="6" textAlign="center">
                          <Box align="center" mb="10">
                            <Image
                              boxSize="100px"
                              objectFit="cover"
                              src="https://i.imgur.com/0BNTHtH.png"
                              alt="Dan Abramov"
                            />
                          </Box>

                          <Text fontWeight="800">Reset Password</Text>
                        </FormControl>

                        <FormControl
                          isInvalid={
                            formik.errors.newPassword &&
                            formik.touched.newPassword
                          }
                          mb="3"
                        >
                          <FormLabel>New Password</FormLabel>
                          <Field
                            as={Input}
                            id="newPassword"
                            name="newPassword"
                            type="password"
                            disabled={authLoading}
                          />
                          <FormErrorMessage>
                            {formik.errors.newPassword}
                          </FormErrorMessage>
                        </FormControl>

                        <FormControl
                          isInvalid={
                            formik.errors.confirmNewPassword &&
                            formik.touched.confirmNewPassword
                          }
                          mb="3"
                        >
                          <FormLabel>Confirm New Password</FormLabel>
                          <Field
                            as={Input}
                            id="confirmNewPassword"
                            name="confirmNewPassword"
                            type="password"
                            disabled={authLoading}
                          />
                          <FormErrorMessage>
                            {formik.errors.confirmNewPassword}
                          </FormErrorMessage>
                        </FormControl>

                        <Stack spacing={10}>
                          <Stack
                            direction={{ base: 'column', sm: 'row' }}
                            align={'start'}
                            justify={'space-between'}
                          ></Stack>
                          <Button type="submit" colorScheme="blue">
                            Reset Password
                          </Button>
                        </Stack>
                      </Box>
                    </form>
                  )}
                </Formik>
              </Box>
            </Flex>
          )}
        </Box>
      )}
    </Fragment>
  );
};

export default AuthAction;
