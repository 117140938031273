import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useToast } from '@chakra-ui/react';
import {
  applyActionCode,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithRedirect,
  signOut,
} from 'firebase/auth';
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  Timestamp,
  where,
  updateDoc,
} from 'firebase/firestore';
import { USER_TYPES } from '../utils/constants';
import app from '../utils/firebase';
import { makeid, sleep } from '../utils/generalFunctions';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const [authUser, setAuthUser] = useState(null);
  const [applicationData, setApplicationData] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);
  const [agentInfo, setAgentInfo] = useState(null);

  const location = useLocation();
  const toast = useToast();

  const db = getFirestore(app);

  useEffect(() => {
    // generateAndSaveReferralsForAllUsers();
    const fetchData = async () => {
      const auth = getAuth(app);

      // generateAndSaveReferralsForAllUsers();

      await onAuthStateChanged(auth, async user => {
        setAuthLoading(true);
        if (user) {
          const docRef = doc(db, 'users', user.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            localStorage.setItem('token', user.accessToken);
            fetchUserAgentDetails(docSnap.data());
            setAuthUser({
              accessToken: user.accessToken,
              displayName: user.displayName,
              email: user.email,
              emailVerified: user.emailVerified,
              photoURL: user.photoURL,
              uid: user.uid,
              data: docSnap.data(),
            });

            updateUserFields({
              lastLogin: Timestamp.now(),
            });

            if (
              location.pathname == '/signup' ||
              location.pathname == '/login'
            ) {
              // navigate('/');
              handleNavigation(docSnap.data());
            }
          } else {
            await setDoc(doc(db, 'users', auth.currentUser.uid), {
              email: auth.currentUser.email,
              applicationCompleted: false,
              qualifiedAmount: 0,
              isAdmin: false,
              user_id: makeid(7),
              account: {
                accountCreated: Timestamp.now(),
                nudge_8hr_sent: false,
                nudge_56hr_sent: false,
              },
              lastLogin: Timestamp.now(),
            });

            const docSnap2 = await getDoc(docRef);

            setAuthUser({
              accessToken: user.accessToken,
              displayName: user.displayName,
              email: user.email,
              emailVerified: user.emailVerified,
              photoURL: user.photoURL,
              uid: user.uid,
              data: docSnap2.data(),
            });

            fetchUserAgentDetails(docSnap2.data());

            if (
              location.pathname == '/signup' ||
              location.pathname == '/login'
            ) {
              // navigate('/');
              handleNavigation(docSnap2.data());
            }
          }
          setAuthLoading(false);
        } else {
          setAuthUser(null);
          setAuthLoading(false);
        }
      });
    };

    fetchData().catch(console.error);
  }, []);

  const handleNavigation = user => {
    if (user.type === USER_TYPES.AGENT && user.applicationCompleted) {
      navigate('/agents/home');
    } else {
      navigate('/');
    }
  };

  const getUserByDocId = async id => {
    const docRef = doc(db, 'users', id);
    const docSnap = await getDoc(docRef);

    return docSnap.data();
  };

  const generateReferralCode = async (name, userId) => {
    const username = name.split(' ')[0].charAt(0) + name.split(' ')[1];
    const referralRef = collection(db, 'referrals');
    const q = query(referralRef, where('code', '==', username));

    const querySnapshot = await getDocs(q);

    if (querySnapshot.docs.length == 0) {
      const code = username;
      const payload = {
        userId: userId,
        code,
        isActive: true,
      };

      await setDoc(doc(db, 'referrals', userId), payload);
      return code;
    } else {
      let uniqueCode = '';
      let isUniqueCodeFound = false;
      while (!isUniqueCodeFound) {
        const randomNum = Math.floor(Math.random() * 1000);
        uniqueCode = username + randomNum;
        const q = query(referralRef, where('code', '==', uniqueCode));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.docs.length == 0) {
          isUniqueCodeFound = true;
        }
      }

      const payload = {
        userId: userId,
        code: uniqueCode,
        isActive: true,
      };

      await setDoc(doc(db, 'referrals', userId), payload);
      return uniqueCode;
    }
  };

  const generateAndSaveReferralsForAllUsers = async () => {
    const clientRef = collection(db, 'users');
    const q = query(clientRef, where('type', '==', USER_TYPES.AGENT));
    const querySnapshot = await getDocs(q);
    querySnapshot.docs.forEach(async document => {
      const data = document.data();
      const referral = await getReferralCodeByUserId(document.id);

      if (!referral) {
        const agentName =
          data.legalName?.firstName + ' ' + data.legalName?.lastName;
        console.log(
          '🚀 ~ file: AuthContext.js:193 ~ generateAndSaveReferralsForAllUsers ~ agentName:',
          agentName
        );

        generateReferralCode(agentName, document.id);
      }
    });
  };

  const saveInvitation = async payload => {
    const agentRef = collection(db, 'agent_invites');
    await addDoc(agentRef, payload);
  };

  const handleSignUp = async (
    email,
    password,
    userType,
    firstName,
    lastName,
    agent,
    isReferral = false
  ) => {
    try {
      setAuthLoading(true);
      const auth = getAuth(app);
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      await sendEmailVerification(auth.currentUser);

      // updateInvitationStatus(email, 'joined');

      // Generate a unique referral code based on the user's email
      if (userType === USER_TYPES.AGENT) {
        await generateReferralCode(
          `${firstName} ${lastName}`,
          auth.currentUser.uid
        );
      }

      let payload = {
        email: auth.currentUser.email,
        applicationCompleted: false,
        qualifiedAmount: 0,
        isAdmin: false,
        user_id: makeid(7),
        type: userType,
        isDeleted: false,
        accountCreated: Timestamp.now(),
        account: {
          accountCreated: Timestamp.now(),
          nudge_8hr_sent: false,
          nudge_56hr_sent: false,
        },
      };

      /**
       * payload for storing invitation in case of referral
       */

      if (firstName || lastName) {
        payload.legalName = {
          firstName,
          lastName,
        };
      }

      if (agent) {
        payload.agent = agent;
      }

      if (isReferral) {
        let invitePayload = {
          agent,
          clientName: `${firstName ?? ''} ${lastName ?? ''}`,
          clientEmail: email,
          isAgent: userType === USER_TYPES.AGENT ? true : false,
          isRejected: false,
          createdAt: Timestamp.now(),
          numReminders: 0,
          clientResponded: false,
          isInvited: true,
          referral: true,
          emailPreferences: {
            disableMarketingEmails: false,
          },
        };

        await saveInvitation(invitePayload);
      }

      await setDoc(doc(db, 'users', auth.currentUser.uid), payload);

      // setAuthLoading(false);

      if (userCredential.user) {
        return { error: false };
      }

      window.location.reload();

      return { error: 'Something went wrong' };
    } catch (err) {
      console.log('Error on handleSignUp: ', err);
      setAuthUser(null);
      setAuthLoading(false);

      if (err.code) {
        return { error: 'Something went wrong: ' + err.code };
      }
      return { error: 'Something went wrong' };
    }
  };

  const handleLogIn = async (email, password) => {
    try {
      setAuthLoading(true);
      const auth = getAuth(app);
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      if (userCredential.user) {
        return { error: false, errorMessage: '' };
      }

      window.location.reload();

      return { error: true, errorMessage: 'Something went wrong' };
    } catch (err) {
      console.log('Error on handleLogIn: ', err);
      setAuthUser(null);
      setAuthLoading(false);

      if (err.code) {
        return {
          error: true,
          errorMessage: err.code,
        };
      }
      return { error: true, errorMessage: 'Something went wrong' };
    }
  };

  const handleGoogleSignUp = async () => {
    try {
      setAuthLoading(true);
      const provider = new GoogleAuthProvider();
      const auth = getAuth(app);
      const result = await signInWithRedirect(auth, provider);

      setAuthLoading(false);

      if (result.user) {
        return { error: false };
      }

      return { error: 'Something went wrong' };
    } catch (err) {
      console.log('Error on handleGoogleSignUp: ', err);
      setAuthUser(null);
      setAuthLoading(false);

      if (err.code) {
        return { error: 'Something went wrong: ' + err.code };
      }
      return { error: 'Something went wrong' };
    }
  };

  const handleGoogleLogIn = async () => {
    try {
      setAuthLoading(true);
      const provider = new GoogleAuthProvider();
      const auth = getAuth(app);
      const result = await signInWithRedirect(auth, provider);
      setAuthLoading(false);

      if (result.user) {
        return { error: false };
      }

      return { error: 'Something went wrong' };
    } catch (err) {
      console.log('Error on handleGoogleLogIn: ', err);
      setAuthUser(null);
      setAuthLoading(false);

      if (err.code) {
        return { error: 'Something went wrong: ' + err.code };
      }
      return { error: 'Something went wrong' };
    }
  };

  const handleSignOut = async () => {
    try {
      const auth = getAuth(app);
      setAuthLoading(true);
      await signOut(auth);
      setAuthUser(null);
      setAuthLoading(false);

      return {};
    } catch (err) {
      console.log('Error on handleSignOut: ', err);
      setAuthLoading(false);
    }
  };

  const handleFirebaseAction = async (
    mode,
    actionCode,
    continueUrl,
    lang,
    newPassword = ''
  ) => {
    try {
      const auth = getAuth(app);

      // Handle the user management action.
      switch (mode) {
        case 'resetPassword':
          // Display reset password handler and UI.
          handleResetPassword(auth, actionCode, continueUrl, lang, newPassword);
          break;
        case 'recoverEmail':
          // Display email recovery handler and UI.
          // handleRecoverEmail(auth, actionCode, lang)
          break;
        case 'verifyEmail':
          // Display email verification handler and UI.
          handleVerifyEmail(auth, actionCode, continueUrl, lang);
          break;
        default:
        // Error: invalid mode.
      }

      navigate('/');
    } catch (err) {
      console.log('Error on handleFirebaseAction: ', err);
      setAuthLoading(false);
    }
  };

  const getUserFromFirestore = async userId => {
    const docRef = doc(db, 'users', userId);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  };

  const updateUserData = async () => {
    const auth = getAuth(app);
    const docRef = doc(db, 'users', auth.currentUser.uid);
    const docSnap = await getDoc(docRef);
    setAuthUser(docSnap.data());
  };

  const handleVerifyEmail = async (auth, actionCode, continueUrl, lang) => {
    setAuthLoading(true);

    applyActionCode(auth, actionCode)
      .then(async resp => {
        const userEmail = auth.currentUser.email;
        const user = await getUserFromFirestore(auth.currentUser?.uid);

        const isAgent = user.type == USER_TYPES.AGENT;

        let backendUrl = `${process.env.REACT_APP_SERVER_URL}/api/v1/email/welcome_email`;
        let params = {
          emailTo: userEmail,
        };

        if (isAgent) {
          backendUrl = `${process.env.REACT_APP_SERVER_URL}/api/v1/email/agent_welcome_email`;
          params = {
            emailTo: userEmail,
            agentName: user?.legalName?.firstName,
          };
        }

        toast({
          title: 'Email Verified.',
          description: 'You email has been verified.',
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top-right',
        });
        await axios({
          method: 'post',
          url: backendUrl,
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify(params),
        });

        await sleep(1000);
        window.location.reload(false);
      })
      .catch(error => {
        toast({
          title: 'Email Already Verified',
          description: 'You email has already been verified.',
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'top-right',
        });
        console.log('Error on handleVerifyEmail: ', error);
        setAuthLoading(true);
        window.location.reload(false);
      });
  };

  const handleResetPassword = async (
    auth,
    actionCode,
    continueUrl,
    lang,
    newPassword
  ) => {
    setAuthLoading(true);
    confirmPasswordReset(auth, actionCode, newPassword)
      .then(async resp => {
        toast({
          title: 'New Password Set.',
          description: 'You password has been reset.',
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top-right',
        });
        await sleep(1000);
        window.location.reload(false);
      })
      .catch(error => {
        console.log('Error on handleVerifyEmail: ', error);
      });
  };

  const handleSendPasswordResetEmail = async email => {
    try {
      setAuthLoading(true);
      const auth = getAuth(app);
      await sendPasswordResetEmail(auth, email);
      setAuthLoading(false);

      return { error: false, errorMessage: '' };
    } catch (err) {
      console.log('Error on handleSendPasswordResetEmail: ', err);
      setAuthLoading(false);

      return { error: true, errorMessage: err };
    }
  };

  const updateUserFields = async updatedField => {
    const auth = getAuth(app);
    const docRef = doc(db, 'users', auth.currentUser.uid);
    await setDoc(docRef, updatedField, {
      merge: true,
    });
    // await getAllAgentsData();
  };

  const handleResendEmailVerification = async () => {
    setAuthLoading(true);
    const auth = getAuth(app);

    await sendEmailVerification(auth.currentUser);
    toast({
      title: 'New Email Verification Resent.',
      description: 'Please verify your email through the new email.',
      status: 'success',
      duration: 9000,
      isClosable: true,
      position: 'top-right',
    });

    setAuthLoading(false);
  };

  const checkIfUserExistsbyEmail = async userEmail => {
    let data = [];
    const clientRef = collection(db, 'users');
    const q = query(clientRef, where('email', '==', userEmail));
    const querySnapshot = await getDocs(q);
    querySnapshot.docs.forEach(doc => {
      data.push(doc.data());
    });

    return data[0];
  };

  const fetchUserAgentDetails = async user => {
    if (!user.agent) {
      return;
    }
    const docRef = doc(db, 'users', user?.agent);
    const docSnap = await getDoc(docRef);

    const applicationRef = doc(db, 'agent_application_responses', user?.agent);
    const applicationDocSnap = await getDoc(applicationRef);

    const applicationdetails = applicationDocSnap.data();

    let data = {
      ...docSnap.data(),
      phone: applicationdetails?.stepOne[0]?.answer,
    };

    setAgentInfo(data);

    return data;
  };

  const checkIfUserInvited = async userEmail => {
    const existingUser = await checkIfUserExistsbyEmail(userEmail);

    if (existingUser) {
      throw 'user already exists';
    }

    let data = [];
    const clientRef = collection(db, 'agent_invites');
    const q = query(clientRef, where('clientEmail', '==', userEmail));
    const querySnapshot = await getDocs(q);
    querySnapshot.docs.forEach(doc => {
      data.push(doc.data());
    });

    let agentInfo;

    if (data[0]) {
      if (!Boolean(data[0].isClientInvite)) {
        const docRef = doc(db, 'users', data[0].agent);
        const docSnap = await getDoc(docRef);
        agentInfo = docSnap.data();
      }
    }

    console.log(
      '🚀 ~ file: AuthContext.js:642 ~ checkIfUserInvited ~ agentInfo:',
      data[0]
    );

    if (agentInfo) {
      return {
        invite: data[0],
        agent: agentInfo,
      };
    } else {
      return null;
    }
  };

  const getAgentByEmail = async email => {
    if (!email) {
      return null;
    }

    let data = [];
    const clientRef = collection(db, 'users');
    const q = query(
      clientRef,
      where('email', '==', email),
      where('type', '==', USER_TYPES.AGENT)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.docs.forEach(doc => {
      data.push({ ...doc.data(), uid: doc.id });
    });
    let user = data[0];

    return user;
  };

  const getCurrentUserReferralCode = async () => {
    const auth = getAuth(app);

    let data = [];
    const clientRef = collection(db, 'referrals');
    const q = query(
      clientRef,
      where('userId', '==', auth.currentUser.uid),
      where('isActive', '==', true)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.docs.forEach(doc => {
      data.push({ ...doc.data(), uid: doc.id });
    });
    let referral = data[0];

    return referral;
  };

  const getReferralCodeByUserId = async userId => {
    let data = [];
    const clientRef = collection(db, 'referrals');
    const q = query(
      clientRef,
      where('userId', '==', userId),
      where('isActive', '==', true)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.docs.forEach(doc => {
      data.push({ ...doc.data(), uid: doc.id });
    });
    let referral = data[0];

    return referral;
  };

  const getReferralByCode = async value => {
    let data = [];
    const clientRef = collection(db, 'referrals');
    const q = query(clientRef, where('code', '==', value));
    const querySnapshot = await getDocs(q);
    querySnapshot.docs.forEach(doc => {
      data.push({ ...doc.data(), uid: doc.id });
    });
    let referral = data[0];

    return referral;
  };

  const addNewReferral = async (code, previousId) => {
    /**
     * make previous code inactive
     */

    const updateRef = doc(db, 'referrals', previousId);
    await setDoc(
      updateRef,
      {
        isActive: false,
      },
      { merge: true }
    );

    /**
     * check if code already exists and make existing code active
     */
    const existingReferral = await await getReferralByCode(code);

    if (existingReferral) {
      const updateRef = doc(db, 'referrals', existingReferral.uid);
      await setDoc(
        updateRef,
        {
          isActive: true,
        },
        { merge: true }
      );
    } else {
      /**
       * add new code
       */
      const auth = getAuth(app);
      const payload = {
        userId: auth.currentUser.uid,
        code,
        isActive: true,
      };

      const ref = collection(db, 'referrals');
      await addDoc(ref, payload);
    }
  };

  const updateEmailPreferences = async () => {
    try {
      const q = query(collection(db, 'users'));

      const querySnapshot = await getDocs(q);

      let userIds = [];
      querySnapshot.forEach(async doc => {
        userIds.push(doc.id);
      });

      for (let i = 0; i < userIds.length; i++) {
        const currentUserId = userIds[i];
        const usersRef = doc(db, 'users', currentUserId);

        await updateDoc(usersRef, {
          emailPreferences: {
            homebuyerEmailPreferences: {
              disallowMarketingEmails: false,
            },
            cobuyerEmailPreferences: {},
            agentEmailPreferences: {
              disallowMarketingEmails: false,
            },
          },
        });

        console.log('Updated: ', currentUserId);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getUserById = async id => {
    try {
      const docRef = doc(db, 'agent_invites', id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return docSnap.data();
      } else {
        console.log('No such document!');
        return null;
      }
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  const updateUserEmailPreferences = async (id, preference) => {
    try {
      const usersRef = doc(db, 'agent_invites', id);

      await updateDoc(usersRef, {
        emailPreferences: {
          disableMarketingEmails: preference,
        },
      });

      console.log('Updated: ', id);
    } catch (err) {
      console.log(err);
    }
  };

  const value = {
    authUser,
    authLoading,
    applicationData,
    handleSignUp,
    handleLogIn,
    handleGoogleSignUp,
    handleGoogleLogIn,
    handleSignOut,
    handleFirebaseAction,
    handleSendPasswordResetEmail,
    setAuthLoading,
    updateUserFields,
    updateUserData,
    handleResendEmailVerification,
    checkIfUserInvited,
    agentInfo,
    getAgentByEmail,
    fetchUserAgentDetails,
    handleNavigation,
    getCurrentUserReferralCode,
    getReferralByCode,
    addNewReferral,
    updateEmailPreferences,
    getUserById,
    updateUserEmailPreferences,
    getUserByDocId,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuth };
