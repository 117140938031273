import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { getFormattedDate } from '../utils/generalFunctions';
import { USER_TYPES } from '../utils/constants';
import { capitalizeFirstLetter } from '../utils/generalFunctions';

import { getAuth, onAuthStateChanged } from 'firebase/auth';
import {
  doc,
  getDoc,
  getFirestore,
  setDoc,
  Timestamp,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
} from 'firebase/firestore';
import {
  getStorage,
  ref,
  uploadBytes,
  listAll,
  getMetadata,
  deleteObject,
} from 'firebase/storage';
import app from '../utils/firebase';
import { documentsEmailToAgent } from '../utils/services/homebuyer.service';
import moment from 'moment';
import 'moment-timezone';

const AuthContext = createContext();

const HomeBuyerAuthProvider = ({ children }) => {
  const db = getFirestore(app);
  const storage = getStorage(app);

  const [authUser, setAuthUser] = useState(null);
  const [applicationData, setApplicationData] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);
  const [coApplicationData, setCoApplicationData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const auth = getAuth(app);

      onAuthStateChanged(auth, async user => {
        setAuthLoading(true);
        if (user) {
          // const docRef = doc(db, 'users', user.uid);
          // const docSnap = await getDoc(docRef);

          // if (docSnap.exists()) {
          // let data = docSnap.data();
          // if (data.type === USER_TYPES.HOME_OWNER) {
          getUserApplication(user.uid);
          // }
          // } else {
          // }
        } else {
        }
        setAuthLoading(false);
      });
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (applicationData && applicationData.uid) {
        setAuthLoading(true);
        const q = query(
          collection(db, 'coapplicant_application_responses'),
          where('homebuyer', '==', applicationData.uid)
        );

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(doc => {
          console.log({ id: doc.id, data: doc.data() });
          setCoApplicationData({ id: doc.id, data: doc.data() });
        });
        setAuthLoading(false);
      }
    };

    fetchData().catch(console.error);
  }, [applicationData]);

  const getUserDocuments = async folderType => {
    console.log('Calling getUserDocuments');
    try {
      const fileList = [];

      const auth = getAuth(app);
      setAuthLoading(true);

      const storage = getStorage();

      const listRef = ref(storage, `${folderType}/${auth.currentUser.uid}`);

      const docsList = await listAll(listRef);

      for (const itemRef of docsList.items) {
        const fileMetadata = await getMetadata(itemRef);

        await fileList.push({
          id: fileMetadata.md5Hash,
          name: fileMetadata.name,
          timeCreated: fileMetadata.timeCreated,
          fileType: fileMetadata.customMetadata.fileTypeName,
          ref: itemRef,
        });
      }

      setAuthLoading(false);

      return fileList;
    } catch (err) {
      console.log('Error on getUserDocuments: ', err);
      setAuthLoading(false);

      return [];
    }
  };

  const handleDocumentDelete = async itemRef => {
    console.log('Calling handleDocumentDelete');
    try {
      setAuthLoading(true);

      await deleteObject(itemRef);
      console.log('file deleted');

      setAuthLoading(false);

      return 'file successfully deleted';
    } catch (err) {
      console.log('Error on handleDocumentDelete: ', err);
      setAuthLoading(false);
    }
  };

  const handleAdditionalFileUpload = async (file, fileType, folderType) => {
    try {
      const auth = getAuth(app);

      setAuthLoading(true);

      const storage = getStorage();

      const storageRef = ref(
        storage,
        `${folderType}/${auth.currentUser.uid}/${file.name}`
      );

      const metadata = {
        customMetadata: {
          fileTypeName: fileType,
        },
      };

      await uploadBytes(storageRef, file, metadata);
      console.log('file uploaded');

      setAuthLoading(false);

      try {
        let agent_name = '';

        if (applicationData?.stepFour[0].answer === 'yes') {
          agent_name =
            applicationData?.stepFour[1]?.answer +
            ' ' +
            applicationData?.stepFour[2]?.answer;
        }

        const stepSeven = applicationData?.stepSeven;

        let client_name =
          stepSeven[0]?.answer +
          ' ' +
          stepSeven[1]?.answer +
          ' ' +
          stepSeven[2]?.answer;

        if (coApplicationData) {
          client_name =
            client_name +
            ' & ' +
            coApplicationData?.data?.name?.firstName +
            ' ' +
            coApplicationData?.data?.name?.lastName;
        }

        const timestamp = Timestamp.now().toDate();
        const estTime = moment(timestamp).tz('America/New_York');
        const formattedTime = estTime.format('MMMM-DD-YYYY [at] hh:mm a [ET]');

        const payload = {
          client_name,
          agent_name: agent_name === '' ? 'No Agent' : agent_name,
          client_email: auth.currentUser?.email,
          submission_date: formattedTime,
          documents: [
            {
              fileType,
            },
          ],
          is_cobuyer_doc:
            folderType === 'cobuyer_additional_documents' ? true : false,
        };

        await documentsEmailToAgent(payload);
      } catch (err) {
        console.log('---error sending support email---', err);
      }

      return {};
    } catch (err) {
      console.log('Error on handleAdditionalFileUpload: ', err);
      setAuthLoading(false);
    }
  };

  const handleSubmitApplication = async (
    applicationResponses,
    applicationFiles
  ) => {
    console.log('Calling handleSubmitApplication');
    try {
      const auth = getAuth(app);
      setAuthLoading(true);

      await setDoc(
        doc(db, 'homebuyer_application_responses', auth.currentUser.uid),
        {
          uid: applicationResponses.uid,
          stepOne: applicationResponses.stepOne,
          stepTwo: applicationResponses.stepTwo,
          stepThree: applicationResponses.stepThree,
          stepFour: applicationResponses.stepFour,
          stepFive: applicationResponses.stepFive,
          stepSix: applicationResponses.stepSix,
          stepSeven: applicationResponses.stepSeven,
          stepEight: applicationResponses.stepEight,
          stepNine: applicationResponses.stepNine,
          stepTen: applicationResponses.stepTen,
          stepEleven: applicationResponses.stepEleven,
          stepFourteen: applicationResponses.stepFourteen,
          stepFifteen: applicationResponses.stepFifteen,
          stepSeventeen: applicationResponses.stepSeventeen,
          timeSubmitted: Timestamp.now(),
        }
      );
      console.log('document uploaded');

      const incomeVerificationDocOneStorageRef = ref(
        storage,
        `homebuyer_application_files/${auth.currentUser.uid}/income_verification/${applicationFiles.stepTwelve.stepTwelveIncomeDocOne.name}`
      );

      await uploadBytes(
        incomeVerificationDocOneStorageRef,
        applicationFiles.stepTwelve.stepTwelveIncomeDocOne
      );

      if (
        applicationFiles.stepTwelve.stepTwelveIncomeDocTwo != '' &&
        applicationFiles.stepTwelve.stepTwelveIncomeDocTwo != null
      ) {
        const incomeVerificationDocOneStorageRef = ref(
          storage,
          `homebuyer_application_files/${auth.currentUser.uid}/income_verification/${applicationFiles.stepTwelve.stepTwelveIncomeDocTwo.name}`
        );
        await uploadBytes(
          incomeVerificationDocOneStorageRef,
          applicationFiles.stepTwelve.stepTwelveIncomeDocTwo
        );
      }

      if (
        applicationFiles.stepTwelve.stepTwelveIncomeDocThree != '' &&
        applicationFiles.stepTwelve.stepTwelveIncomeDocThree != null
      ) {
        const incomeVerificationDocOneStorageRef = ref(
          storage,
          `homebuyer_application_files/${auth.currentUser.uid}/income_verification/${applicationFiles.stepTwelve.stepTwelveIncomeDocThree.name}`
        );
        await uploadBytes(
          incomeVerificationDocOneStorageRef,
          applicationFiles.stepTwelve.stepTwelveIncomeDocThree
        );
      }
      console.log('Income verification upload');

      const bankStatementDocOneStorageRef = ref(
        storage,
        `homebuyer_application_files/${auth.currentUser.uid}/bank_statement/${applicationFiles.stepThirteen.stepThirteenBankStatementOne.name}`
      );
      await uploadBytes(
        bankStatementDocOneStorageRef,
        applicationFiles.stepThirteen.stepThirteenBankStatementOne
      );

      if (
        applicationFiles.stepThirteen.stepThirteenBankStatementTwo != '' &&
        applicationFiles.stepThirteen.stepThirteenBankStatementTwo != null
      ) {
        const incomeVerificationDocOneStorageRef = ref(
          storage,
          `homebuyer_application_files/${auth.currentUser.uid}/bank_statement/${applicationFiles.stepThirteen.stepThirteenBankStatementTwo.name}`
        );
        await uploadBytes(
          incomeVerificationDocOneStorageRef,
          applicationFiles.stepThirteen.stepThirteenBankStatementTwo
        );
      }

      if (
        applicationFiles.stepThirteen.stepThirteenBankStatementThree != '' &&
        applicationFiles.stepThirteen.stepThirteenBankStatementThree != null
      ) {
        const incomeVerificationDocOneStorageRef = ref(
          storage,
          `homebuyer_application_files/${auth.currentUser.uid}/bank_statement/${applicationFiles.stepThirteen.stepThirteenBankStatementThree.name}`
        );
        await uploadBytes(
          incomeVerificationDocOneStorageRef,
          applicationFiles.stepThirteen.stepThirteenBankStatementThree
        );
      }
      console.log('Bank statement upload');

      const backgroundCheckStorageRef = ref(
        storage,
        `homebuyer_application_files/${auth.currentUser.uid}/background_check/${applicationFiles.stepSixteen.name}`
      );
      await uploadBytes(
        backgroundCheckStorageRef,
        applicationFiles.stepSixteen
      );
      console.log('Background check upload');

      const usersRef = doc(db, 'users', auth.currentUser.uid);
      await updateDoc(usersRef, {
        applicationCompleted: true,
        addCoBuyerAfterSubmission: false,
        legalName: {
          firstName: applicationResponses.legalName.firstName,
          lastName: applicationResponses.legalName.lastName,
        },
      });
      console.log('document updated');

      await axios({
        method: 'post',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/email/prequalified_email`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          emailTo: auth.currentUser.email,
          firstName: capitalizeFirstLetter(
            applicationResponses.legalName.firstName
          ),
        }),
      });
      console.log('email sent');
      updateInvitationStatus(auth.currentUser.email.toLowerCase(), 'joined');

      // if agent added
      if (
        applicationResponses.stepFour[0].answer == 'yes' &&
        applicationResponses.stepFour[1].answer &&
        applicationResponses.stepFour[2].answer &&
        applicationResponses.stepFour[3].answer
      ) {
        await axios({
          method: 'post',
          url: `${process.env.REACT_APP_SERVER_URL}/api/v1/email/agent_email_from_user`,
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify({
            agentFirstName: capitalizeFirstLetter(
              applicationResponses.stepFour[1].answer
            ),
            agentLastName: capitalizeFirstLetter(
              applicationResponses.stepFour[2].answer
            ),
            userFirstName: capitalizeFirstLetter(
              applicationResponses.legalName.firstName
            ),
            userLastName: capitalizeFirstLetter(
              applicationResponses.legalName.lastName
            ),
            submissionDate: getFormattedDate(
              new Date(Timestamp.now().seconds * 1000)
            ),
            emailTo: applicationResponses.stepFour[3].answer,
          }),
        });
        console.log('agent email sent');
      }
      // if coapplicant added
      // if (
      //   applicationResponses.stepFive[0].answer == 'yes' &&
      //   applicationResponses.stepFive[1].answer &&
      //   applicationResponses.stepFive[2].answer &&
      //   applicationResponses.stepFive[3].answer
      // ) {
      //   await axios({
      //     method: 'post',
      //     url: `${process.env.REACT_APP_SERVER_URL}/api/v1/email/coapplicant_email_from_user`,
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //     data: JSON.stringify({
      //       coapplicantFirstName: capitalizeFirstLetter(
      //         applicationResponses.stepFive[1].answer
      //       ),
      //       coapplicantLasttName: capitalizeFirstLetter(
      //         applicationResponses.stepFive[2].answer
      //       ),
      //       userFirstName: capitalizeFirstLetter(
      //         applicationResponses.legalName.firstName
      //       ),
      //       userLastName: capitalizeFirstLetter(
      //         applicationResponses.legalName.lastName
      //       ),
      //       submissionDate: getFormattedDate(
      //         new Date(Timestamp.now().seconds * 1000)
      //       ),
      //       emailTo: applicationResponses.stepFive[3].answer,
      //     }),
      //   });
      //   console.log('agent email sent');
      // }

      //   setAuthLoading(false)

      // refresh screen after submitting application
      window.location.reload(false);

      return {};
    } catch (err) {
      console.log('Error on handleSubmitApplication: ', err);
      setAuthLoading(false);
    }
  };

  const handleSubmitDocuments = async (
    applicationResponses,
    applicationFiles
  ) => {
    try {
      console.log('Running handleSubmitDocuments()');
      const auth = getAuth(app);
      setAuthLoading(true);

      const incomeVerificationDocOneStorageRef = ref(
        storage,
        `homebuyer_application_files/${auth.currentUser.uid}/income_verification/${applicationFiles.stepTwelve.stepTwelveIncomeDocOne.name}`
      );
      await uploadBytes(
        incomeVerificationDocOneStorageRef,
        applicationFiles.stepTwelve.stepTwelveIncomeDocOne
      );

      if (
        applicationFiles.stepTwelve.stepTwelveIncomeDocTwo != '' &&
        applicationFiles.stepTwelve.stepTwelveIncomeDocTwo != null
      ) {
        const incomeVerificationDocOneStorageRef = ref(
          storage,
          `homebuyer_application_files/${auth.currentUser.uid}/income_verification/${applicationFiles.stepTwelve.stepTwelveIncomeDocTwo.name}`
        );
        await uploadBytes(
          incomeVerificationDocOneStorageRef,
          applicationFiles.stepTwelve.stepTwelveIncomeDocTwo
        );
      }

      if (
        applicationFiles.stepTwelve.stepTwelveIncomeDocThree != '' &&
        applicationFiles.stepTwelve.stepTwelveIncomeDocThree != null
      ) {
        const incomeVerificationDocOneStorageRef = ref(
          storage,
          `homebuyer_application_files/${auth.currentUser.uid}/income_verification/${applicationFiles.stepTwelve.stepTwelveIncomeDocThree.name}`
        );
        await uploadBytes(
          incomeVerificationDocOneStorageRef,
          applicationFiles.stepTwelve.stepTwelveIncomeDocThree
        );
      }
      console.log('Income verification upload');

      const bankStatementDocOneStorageRef = ref(
        storage,
        `homebuyer_application_files/${auth.currentUser.uid}/bank_statement/${applicationFiles.stepThirteen.stepThirteenBankStatementOne.name}`
      );
      await uploadBytes(
        bankStatementDocOneStorageRef,
        applicationFiles.stepThirteen.stepThirteenBankStatementOne
      );

      if (
        applicationFiles.stepThirteen.stepThirteenBankStatementTwo != '' &&
        applicationFiles.stepThirteen.stepThirteenBankStatementTwo != null
      ) {
        const incomeVerificationDocOneStorageRef = ref(
          storage,
          `homebuyer_application_files/${auth.currentUser.uid}/bank_statement/${applicationFiles.stepThirteen.stepThirteenBankStatementTwo.name}`
        );
        await uploadBytes(
          incomeVerificationDocOneStorageRef,
          applicationFiles.stepThirteen.stepThirteenBankStatementTwo
        );
      }

      if (
        applicationFiles.stepThirteen.stepThirteenBankStatementThree != '' &&
        applicationFiles.stepThirteen.stepThirteenBankStatementThree != null
      ) {
        const incomeVerificationDocOneStorageRef = ref(
          storage,
          `homebuyer_application_files/${auth.currentUser.uid}/bank_statement/${applicationFiles.stepThirteen.stepThirteenBankStatementThree.name}`
        );
        await uploadBytes(
          incomeVerificationDocOneStorageRef,
          applicationFiles.stepThirteen.stepThirteenBankStatementThree
        );
      }
      console.log('Bank statement upload');

      const backgroundCheckStorageRef = ref(
        storage,
        `homebuyer_application_files/${auth.currentUser.uid}/background_check/${applicationFiles.stepSixteen.name}`
      );
      await uploadBytes(
        backgroundCheckStorageRef,
        applicationFiles.stepSixteen
      );
      console.log('Background check upload');

      const usersRef = doc(db, 'users', auth.currentUser.uid);
      await updateDoc(usersRef, {
        applicationCompleted: true,
        addCoBuyerAfterSubmission: false,
        legalName: {
          firstName: applicationResponses.legalName.firstName,
          lastName: applicationResponses.legalName.lastName,
        },
      });
      console.log('document updated');

      await axios({
        method: 'post',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/email/prequalified_email`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          emailTo: auth.currentUser.email,
          firstName: capitalizeFirstLetter(
            applicationResponses.legalName.firstName
          ),
        }),
      });
      console.log('email sent');

      // if agent added
      if (
        applicationResponses.stepFour[0].answer == 'yes' &&
        applicationResponses.stepFour[1].answer &&
        applicationResponses.stepFour[2].answer &&
        applicationResponses.stepFour[3].answer
      ) {
        await axios({
          method: 'post',
          url: `${process.env.REACT_APP_SERVER_URL}/api/v1/email/agent_email_from_user`,
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify({
            agentFirstName: capitalizeFirstLetter(
              applicationResponses.stepFour[1].answer
            ),
            agentLastName: capitalizeFirstLetter(
              applicationResponses.stepFour[2].answer
            ),
            userFirstName: capitalizeFirstLetter(
              applicationResponses.legalName.firstName
            ),
            userLastName: capitalizeFirstLetter(
              applicationResponses.legalName.lastName
            ),
            submissionDate: getFormattedDate(
              new Date(Timestamp.now().seconds * 1000)
            ),
            emailTo: applicationResponses.stepFour[3].answer,
          }),
        });
        console.log('agent email sent');
      }
      // if coapplicant added
      // if (
      //   applicationResponses.stepFive[0].answer == 'yes' &&
      //   applicationResponses.stepFive[1].answer &&
      //   applicationResponses.stepFive[2].answer &&
      //   applicationResponses.stepFive[3].answer
      // ) {
      //   await axios({
      //     method: 'post',
      //     url: `${process.env.REACT_APP_SERVER_URL}/api/v1/email/coapplicant_email_from_user`,
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //     data: JSON.stringify({
      //       coapplicantFirstName: capitalizeFirstLetter(
      //         applicationResponses.stepFive[1].answer
      //       ),
      //       coapplicantLasttName: capitalizeFirstLetter(
      //         applicationResponses.stepFive[2].answer
      //       ),
      //       userFirstName: capitalizeFirstLetter(
      //         applicationResponses.legalName.firstName
      //       ),
      //       userLastName: capitalizeFirstLetter(
      //         applicationResponses.legalName.lastName
      //       ),
      //       submissionDate: getFormattedDate(
      //         new Date(Timestamp.now().seconds * 1000)
      //       ),
      //       emailTo: applicationResponses.stepFive[3].answer,
      //     }),
      //   });
      //   console.log('agent email sent');
      // }

      return {};
    } catch (err) {
      console.log('Error on handleSubmitApplication: ', err);
      setAuthLoading(false);
    }
  };

  const handleFileUpload = async file => {
    console.log('Calling handleFileUpload');
    try {
      const auth = getAuth(app);
      setAuthLoading(true);
      const storageRef = ref(
        storage,
        `homebuyer_application_files/${file.name}`
      );
      await uploadBytes(storageRef, file);
      console.log('file uploaded');
      setAuthLoading(false);

      return {};
    } catch (err) {
      console.log('Error on handleFileUpload: ', err);
      setAuthLoading(false);
    }
  };

  const handleApplicationStarted = async () => {
    console.log('Calling handleApplicationStarted');
    try {
      const auth = getAuth(app);
      const usersRef = doc(db, 'users', auth.currentUser.uid);
      const userSnap = await getDoc(usersRef);
      if (userSnap.data().applicationCompleted == false) {
        const prod = userSnap.data().applicationStartDate;
        if (prod == undefined) {
          await updateDoc(usersRef, {
            applicationStartDate: Timestamp.now(),
          });
          console.log('document updated');
        }
      }
    } catch (err) {
      console.log('Error on handleApplicationStarted: ', err);

      return { error: true, errorMessage: err };
    }
  };

  const updateHomebuyerFields = async updatedField => {
    try {
      const auth = getAuth(app);
      const docRef = doc(db, 'homebuyers', auth.currentUser.uid);
      await setDoc(docRef, updatedField, {
        merge: true,
      });
    } catch (err) {
      console.log('updateHomebuyerFields() ERROR: ', err);
    }
  };

  const getUserApplication = async userId => {
    try {
      const docRef = doc(db, 'homebuyer_application_responses', userId);
      const docSnap = await getDoc(docRef);
      setApplicationData(docSnap.data());
      return docSnap.data();
    } catch (err) {
      console.log('getUserApplication() ERROR: ', err);
    }
  };

  const updateApplicationFields = async updatedField => {
    try {
      const auth = getAuth(app);
      const docRef = doc(
        db,
        'homebuyer_application_responses',
        auth.currentUser?.uid
      );
      await setDoc(docRef, updatedField, {
        merge: true,
      });
    } catch (err) {
      console.log('updateApplicationFields() ERROR: ', err);
    }
  };

  const updateInvitationStatus = async (userEmail, status) => {
    const clientRef = collection(db, 'agent_invites');
    const q = query(clientRef, where('clientEmail', '==', userEmail));
    const querySnapshot = await getDocs(q);

    querySnapshot.docs.forEach(async doc => {
      updateDoc(doc.ref, {
        status: status,
      });
    });
  };

  const updateCoBuyerInfo = async (
    firstName,
    lastName,
    email,
    phoneNumber,
    grossMonthlyIncome
  ) => {
    try {
      console.log('Running updateCoBuyerInfo()');

      const auth = getAuth(app);
      const docRef = doc(
        db,
        'homebuyer_application_responses',
        auth.currentUser.uid
      );

      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const stepSix = docSnap.data().stepSix;
        await updateDoc(docRef, {
          stepFive: [
            {
              question: 'Do you have a Co-Applicant?',
              answer: 'yes',
            },
            {
              question:
                'Please enter the details of your Co-Applicant - First Name:',
              answer: firstName,
            },
            {
              question:
                'Please enter the details of your Co-Applicant - Last Name:',
              answer: lastName,
            },
            {
              question:
                'Please enter the details of your Co-Applicant - Email:',
              answer: email,
            },
            {
              question:
                'Please enter the details of your Co-Applicant - Phone Number:',
              answer: phoneNumber,
            },
          ],
          stepSix: [
            stepSix[0],
            stepSix[1],
            stepSix[2],
            stepSix[3],
            {
              question:
                'Please enter the details of your Co-Applicant - Gross Monthly Income:',
              answer: `${grossMonthlyIncome}`,
            },
          ],
        });

        console.log('Application updated with co-applicant info');
      } else {
        // docSnap.data() will be undefined in this case
        console.log('No such document!');
      }

      console.log("Application updated with co-applicant's info");
    } catch (err) {
      console.log('updateCoBuyerInfo() ERROR: ', err);
    }
  };

  const handleCheckForAgent = async email => {
    try {
      console.log('Running handleCheckForAgent()');

      const users = [];
      let userExists = false;
      let isAgent = false;

      const querySnapshot = await getDocs(collection(db, 'users'));
      querySnapshot.forEach(doc => {
        users.push({ ...doc.data() });
      });

      for (let i = 0; i < users.length; i++) {
        if (users[i].email === email) {
          userExists = true;

          if (users[i].type === USER_TYPES.AGENT) {
            isAgent = true;
          }
        }
      }

      return { userExists, isAgent };
    } catch (err) {
      console.log('handleCheckForAgent() ERROR: ', err);
      return false;
    }
  };

  const value = {
    authUser,
    applicationData,
    coApplicationData,
    getUserDocuments,
    setAuthLoading,
    handleSubmitApplication,
    handleFileUpload,
    authLoading,
    handleApplicationStarted,
    updateHomebuyerFields,
    getUserApplication,
    updateApplicationFields,
    handleSubmitDocuments,
    handleAdditionalFileUpload,
    handleDocumentDelete,
    updateCoBuyerInfo,
    updateInvitationStatus,
    handleCheckForAgent,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useHomeBuyerAuth = () => {
  return useContext(AuthContext);
};

export { HomeBuyerAuthProvider, useHomeBuyerAuth };
