import React, { Fragment, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

// context
import { useCoapplicant } from '../context/CoapplicantContext';

// components
import HeaderOnboarding from '../components/Coapplicant/HeaderOnboarding';
import CoapplicantOnboardingProcess from '../components/Coapplicant/CoapplicantOnboardingProcess';

// chakra
import {
  Card,
  CardBody,
  Box,
  Stack,
  Button,
  Flex,
  CardHeader,
  Image,
  Heading,
  Text,
} from '@chakra-ui/react';

const CoapplicantOnboarding = () => {
  const { id } = useParams();
  const { getCoapplicant, setIsHomebuyer } = useCoapplicant();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const fetchData = async () => {
      await getCoapplicant(id);

      const isHomebuyer = searchParams.get('homebuyer');
      if (isHomebuyer == 'true') {
        setIsHomebuyer(true);
      }
    };

    fetchData().catch(console.error);
  }, []);

  return (
    <Fragment>
      <HeaderOnboarding>
        <Box align="center">
          <CoapplicantOnboardingProcess />
        </Box>
      </HeaderOnboarding>
    </Fragment>
  );
};

export default CoapplicantOnboarding;
