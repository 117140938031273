import React, { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import full_logo from '../images/full_logo.png';
import { useNavigate } from 'react-router-dom';

// utils
import { sleep } from '../utils/generalFunctions';

// context
import { useAuth } from '../context/AuthContext';

// chakra
import {
  Box,
  Button,
  Container,
  Heading,
  Image,
  Stack,
  Text,
  Flex,
  Checkbox,
  Spinner,
  Spacer,
} from '@chakra-ui/react';

const EmailUnsubscribe = () => {
  const navigate = useNavigate();
  const { updateEmailPreferences, getUserById, updateUserEmailPreferences } =
    useAuth();
  const { id } = useParams();

  const [emailPreferences, setEmailPreferences] = useState(false);
  const [transition, setTransition] = useState(1);

  const [userId, setUserId] = useState(null);
  const [userDetails, setUserDetails] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setUserId(id);

      const userData = await getUserById(id);
      console.log({ userData });
      setUserDetails(userData);
    };

    fetchData().catch(console.error);
  }, []);

  const handleClickTransition = async () => {
    setTransition(2);
    await updateUserEmailPreferences(userId, true);
    setTransition(3);
  };

  const StateOne = (
    <Stack spacing="4" align="center">
      <Box w="100%" align="left">
        <Text>
          Email preferences for <b>{userDetails?.email}</b>
        </Text>
      </Box>

      <Flex>
        <Checkbox
          size="lg"
          mr="3"
          onChange={e => setEmailPreferences(e.target.checked)}
        />
        <Text>
          I don't want to receive any emails from Utopia Homes (you will still
          receive transactional emails for purchases you make on the site).
        </Text>
      </Flex>
      <Box align="right" w="100%">
        <Button
          colorScheme="blue"
          size="lg"
          isDisabled={!emailPreferences}
          onClick={async () => {
            await handleClickTransition();
          }}
          w="100%"
        >
          Submit
        </Button>
      </Box>
    </Stack>
  );

  const StateTwo = (
    <Stack spacing="4" align="center">
      <Spinner />
    </Stack>
  );

  const StateThree = (
    <Stack spacing="4" align="center">
      <Text>Your email preferences have now been changed</Text>
    </Stack>
  );

  return (
    <Fragment>
      <Box bg="gray.100" w="100%" h="100vh">
        <Flex p="4" bg="white">
          <Container maxW="7xl">
            <Flex align="center">
              <Box
                cursor="pointer"
                onClick={() => {
                  window.location.replace('https://www.utopiahomes.us/');
                }}
              >
                <Image src={full_logo} maxH="40px" objectFit="fit" />
              </Box>

              <Spacer />
              <Button
                onClick={() => {
                  navigate('/');
                }}
              >
                Return to Dashboard
              </Button>
            </Flex>
          </Container>
        </Flex>
        <Box p="4">
          <Container maxW="xl" centerContent>
            <Box p="4" bg="white" w="100%" textAlign="center">
              {transition === 1 && StateOne}
              {transition === 2 && StateTwo}
              {transition === 3 && StateThree}
            </Box>
          </Container>
        </Box>
      </Box>
    </Fragment>
  );
};

export default EmailUnsubscribe;
