import {
  Button,
  Card,
  CardBody,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  ListItem,
  Spinner,
  Text,
  UnorderedList,
  useToast,
} from '@chakra-ui/react';
import { Field, Formik } from 'formik';
import { useState } from 'react';
import SidebarWithHeader from '../components/SidebarWithHeader';
import { getNormalizedError } from '../utils/generalFunctions';
import { inviteUserByEmail } from '../utils/services/agent.service';
import { clientInvitationSchema } from '../validations/ClientInviteSchema';
import { ArrowForwardIcon } from '@chakra-ui/icons';

const HomebuyerInvite = () => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const toast = useToast();

  const handleSubmit = async values => {
    try {
      let payload = {
        ...values,
        isAgent: false,
        isClientInvite: true,
        client_email_address: values.client_email_address?.toLowerCase(),
      };

      setButtonLoading(true);

      await inviteUserByEmail(payload);
      toast({
        title: 'Client Invited',
        description: `${values.client_name} invited successfully`,
        status: 'success',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });
      setButtonLoading(false);
    } catch (err) {
      const error = getNormalizedError(err);

      toast({
        title: 'Request Failed',
        description: error
          ? error
          : 'Unable to invite user at the moment, please try again later',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });

      setButtonLoading(false);
    }
  };

  return (
    <>
      <SidebarWithHeader userMode="onboarding">
        <Container maxW="container.md">
          {/* <Stack spacing="5"> */}
          <Card bg="white" size="lg" mt="5">
            <CardBody>
              <Heading size="lg">Invite Friends & Family!</Heading>

              <Text mt={2} fontWeight={'bold'}>
                How it works
              </Text>
              <UnorderedList my={4}>
                <ListItem>
                  Invite friends and family to join Utopia through Email.
                </ListItem>
                <ListItem>
                  When they sign up using your referral link, they become part
                  of your referral network.
                </ListItem>
                <ListItem>
                  Stay informed about their application progress and their
                  journey with Utopia Homes.
                </ListItem>
                <ListItem>
                  Earn referral rewards or benefits when your referred
                  customer's successfully complete transactions with Utopia.
                </ListItem>
                <ListItem>
                  Keep inviting more people to expand your referral network and
                  increase the potential for earning rewards with each
                  successful referral.
                </ListItem>
              </UnorderedList>

              <Formik
                initialValues={{
                  client_name: '',
                  client_email_address: '',
                }}
                validationSchema={clientInvitationSchema}
                onSubmit={async (values, { resetForm }) => {
                  await handleSubmit(values);
                  resetForm();
                }}
              >
                {formik => (
                  <>
                    <FormControl
                      isInvalid={
                        formik.errors.client_name && formik.touched.client_name
                      }
                      mt={2}
                    >
                      <FormLabel> Full name</FormLabel>
                      <Field
                        as={Input}
                        borderWidth="2px"
                        id="client_name"
                        name="client_name"
                        size="md"
                        borderRadius="md"
                        disabled={buttonLoading}
                        placeholder="John Doe"
                      />
                      <FormErrorMessage>
                        {formik.errors.client_name}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={
                        formik.errors.client_email_address &&
                        formik.touched.client_email_address
                      }
                      mt={2}
                    >
                      <FormLabel> Email Address</FormLabel>
                      <Field
                        as={Input}
                        borderWidth="2px"
                        id="client_email_address"
                        name="client_email_address"
                        size="md"
                        borderRadius="md"
                        type="email"
                        disabled={buttonLoading}
                        placeholder="client@email.com"
                      />
                      <FormErrorMessage>
                        {formik.errors.client_email_address}
                      </FormErrorMessage>
                    </FormControl>

                    <Flex mt={'10%'} justifyContent="center">
                      <Button
                        onClick={() => formik.handleSubmit()}
                        rightIcon={<ArrowForwardIcon />}
                        isDisabled={buttonLoading}
                        colorScheme="blue"
                      >
                        {buttonLoading ? <Spinner /> : ' Send Invite'}
                      </Button>
                    </Flex>
                  </>
                )}
              </Formik>
            </CardBody>
          </Card>
        </Container>
      </SidebarWithHeader>
    </>
  );
};

export default HomebuyerInvite;
